import React from "react";

//mui
import { Skeleton } from "@mui/material";

const HeaderSkeleton = () => {
  return (
    <div className="lg:flex hidden items-center gap-[42px] min-w-[240px]">
      <div className="flex items-center gap-2">
        <div className="w-11 h-11 bg-[#E7D5EEB8] rounded-full justify-center flex items-center py-3 cursor-pointer">
          <div className="w-12 h-12 rounded-full p-1">
            <Skeleton
              animation="wave"
              variant="circular"
              width={40}
              height={40}
              className="lg:block hidden"
            />
          </div>
        </div>
        <Skeleton
          animation="wave"
          width={120}
          height={40}
          className="lg:block hidden"
        />
      </div>
      <Skeleton
        animation="wave"
        variant="circular"
        width={30}
        height={30}
        className="lg:block hidden"
      />
    </div>
  );
};

export default HeaderSkeleton;
