import React from "react";

//mui
import CircularProgress from "@mui/material/CircularProgress";

const Loading = () => {
  return (
    <div className="w-full h-screen">
      <div className="flex items-center w-full h-full justify-center">
        <CircularProgress
          sx={{
            color: "#923FB3",
          }}
        />
      </div>
    </div>
  );
};

export default Loading;
