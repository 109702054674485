import React, { useState, useEffect } from "react";
import Link from "next/link";

//mui icons
import LanguageIcon from "@mui/icons-material/Language";
import { Fade } from "react-awesome-reveal";
import useTranslation from "../hooks/useTranslation";

const SideMenu = ({
  sideMenuIsOpen,
  setsideMenuIsOpen,
  userIsAuth,
  USER,
  active,
  t,
  router,
  handleLogout,
  user,
}) => {
  const { r } = router.query;

  // hide side menu when navigate
  const handleHideSideMenu = () => {
    setsideMenuIsOpen(false);
  };

  const [isMenuProfileOpen, setisMenuProfileOpen] = useState(false);
  const { traduction } = useTranslation();
  return (
    <>
      {sideMenuIsOpen && (
        <div
          className={`fixed lg:hidden top-[20px] left-[20px] transition-[opacity] duration-700 opacity-1 ${
            sideMenuIsOpen && "!opacity-100"
          } z-50 p-[10px]`}
          dir={router.locale === "ar" ? "rtl" : "ltr"}
        >
          <button onClick={() => setsideMenuIsOpen(false)}>
            <img src="/svg/cancel.svg" alt="cancel" />
          </button>
        </div>
      )}

      <div
        className={`fixed lg:hidden w-full h-full bg-white z-40 h-full overflow-y-auto transition duration-300 ${
          router.locale === "ar"
            ? "right-0 -translate-x-full"
            : "left-0 translate-x-full"
        } ${sideMenuIsOpen && "!translate-x-0"}`}
      >
        <div className="w-full py-[100px]">
          <div
            className={`mx-auto w-[220px] flex flex-col gap-[15px] text-center ${
              !userIsAuth && "items-center"
            }`}
          >
            {userIsAuth && (
              <div
                className={`flex flex-col items-center transition-[gap] duration-300 items-start gap-0 ${
                  isMenuProfileOpen && "!gap-[10px]"
                }`}
              >
                <button
                  onClick={() => setisMenuProfileOpen(!isMenuProfileOpen)}
                  className={`flex items-center justify-center gap-[10px]`}
                >
                  <img
                    src={
                      user?.profilePictureUrl
                        ? user?.profilePictureUrl
                        : "/svg/profile.svg"
                    }
                    alt="profile"
                    className="object-fill w-8 h-8 rounded-full"
                  />
                  <span className="text-black font-600 tracking-wider text-[24px] group-hover:text-secondary">
                    {traduction("Mon profil")}
                  </span>
                </button>
                <div
                  className={`rounded-lg  mx-auto flex flex-col items-center gap-[10px] transition-[max-height,opacity] duration-300 max-h-0 overflow-hidden ${
                    isMenuProfileOpen && "!max-h-[200px]"
                  }`}
                >
                  {USER.role === "Freelancer" ? (
                    <Link
                      onClick={handleHideSideMenu}
                      href={"/freelancerProfile?r=profile"}
                    >
                      <div className="flex items-center justify-enter gap-[10px] w-full">
                        <span
                          className={`text-secondary text-[18px] font-600 ${
                            r && r === "profile" && "text-secondary"
                          } `}
                        >
                          {traduction("Mon copmte")}
                        </span>
                      </div>
                    </Link>
                  ) : (
                    <Link
                      onClick={handleHideSideMenu}
                      href={"/clientProfile?r=profile"}
                    >
                      <div className="flex items-center justify-enter gap-[10px] w-full">
                        <span
                          className={`text-secondary text-[18px] font-600 ${
                            r && r === "profile" && "text-secondary"
                          } `}
                        >
                          {traduction("Mon copmte")}
                        </span>
                      </div>
                    </Link>
                  )}
                  {USER.role === "Freelancer" ? (
                    <Link
                      onClick={handleHideSideMenu}
                      href={"/freelancerProfile?r=settings"}
                    >
                      <div className="flex items-center justify-enter gap-[10px] w-full">
                        <span
                          className={`text-secondary text-[18px] font-600 ${
                            r && r === "settings" && "text-secondary"
                          } `}
                        >
                          {traduction("Paramétres du compte")}
                        </span>
                      </div>
                    </Link>
                  ) : (
                    <Link
                      onClick={handleHideSideMenu}
                      href={"/clientProfile?r=settings"}
                    >
                      <div className="flex items-center justify-enter gap-[10px] w-full">
                        <span
                          className={`text-secondary text-[18px] font-600 ${
                            r && r === "settings" && "text-secondary"
                          } `}
                        >
                          {traduction("Paramétres du compte")}
                        </span>
                      </div>
                    </Link>
                  )}
                  {USER.role === "Freelancer" ? (
                    <>
                      <Link
                        onClick={handleHideSideMenu}
                        href={"/freelancerProfile?r=offers&t=pending"}
                      >
                        <div className="flex items-center justify-enter gap-[10px] w-full">
                          <span
                            className={`text-secondary text-[18px] font-600 ${
                              r && r === "offers" && "text-secondary"
                            } `}
                          >
                            {traduction("Mes offres")}
                          </span>
                        </div>
                      </Link>
                      <Link
                        onClick={handleHideSideMenu}
                        href={"/freelancerProfile?r=workSchedule"}
                      >
                        <div className="flex items-center justify-enter gap-[10px] w-full">
                          <span
                            className={`text-secondary text-[18px] font-600 ${
                              r && r === "workSchedule" && "text-secondary"
                            } `}
                          >
                            {traduction("Horaire de travail")}
                          </span>
                        </div>
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link
                        onClick={handleHideSideMenu}
                        href={"/clientProfile?r=orders"}
                      >
                        <div className="flex items-center justify-enter gap-[10px] w-full">
                          <span
                            className={`text-secondary text-[18px] font-600 ${
                              r && r === "orders" && "text-secondary"
                            } `}
                          >
                            {traduction("Mes commandes")}
                          </span>
                        </div>
                      </Link>
                      <Link
                        onClick={handleHideSideMenu}
                        href={"/clientProfile?r=address"}
                      >
                        <div className="flex items-center justify-enter gap-[10px] w-full">
                          <span
                            className={`text-secondary text-[18px] font-600 ${
                              active === "address" && "text-secondary"
                            } `}
                          >
                            {traduction("Mes adresses")}
                          </span>
                        </div>
                      </Link>
                    </>
                  )}
                </div>
              </div>
            )}

            <Link onClick={handleHideSideMenu} href={"/"}>
              <span
                className={` duration-300 text-[24px] font-600 ${
                  active === "home" && "text-secondary"
                } `}
              >
                {t("Accueil")}
              </span>
            </Link>
            <Link onClick={handleHideSideMenu} href={"/feed"}>
              <span
                className={` duration-300 text-[24px] font-600 ${
                  active === "explore" && "text-secondary"
                }`}
              >
                {t("Portail Client")}
              </span>
            </Link>
            {(!userIsAuth || (userIsAuth && USER.role !== "Freelancer")) && (
              <Link onClick={handleHideSideMenu} href={"/recrutement"}>
                <span
                  className={` duration-300 text-[24px] font-600 ${
                    active === "recrutement" && "text-secondary"
                  } `}
                >
                  {t("Portail main d'oeuvre")}
                </span>
              </Link>
            )}
            <Link onClick={handleHideSideMenu} href={"/#about"}>
              <span
                className={` duration-300 text-[24px] font-600 ${
                  active === "about" && "text-secondary"
                } `}
              >
                {t("A propos")}
              </span>
            </Link>
            <div className="pt-[40px]">
              {userIsAuth ? (
                <div
                  className="flex items-center justify-center gap-2 cursor-pointer group"
                  onClick={handleLogout}
                >
                  <div className="active-icon">
                    <img src="/svg/logout.svg" alt="" />
                  </div>
                  <span className="text-black font-600 tracking-wider text-[24px] group-hover:text-secondary">
                    {traduction("Déconnecter")}
                  </span>
                </div>
              ) : (
                <div className="w-full flex flex-col items-center gap-[20px]">
                  <Link
                    onClick={handleHideSideMenu}
                    href="/login"
                    className="text-[1rem] text-[#923FB3] font-600"
                  >
                    {t("login")}
                  </Link>
                  <Link
                    onClick={handleHideSideMenu}
                    href="/register"
                    className="text-[1rem] bg-[#923FB3] text-white p-[10px] rounded-[8px] w-[167px] text-center font-600"
                  >
                    {t("sign up")}
                  </Link>
                </div>
              )}
            </div>
            {/* <Link onClick={handleHideSideMenu} href={"/home"}>
            <span
              className={` duration-300 text-[24px] font-600 ${
                active === "home" && "text-secondary"
              } `}
            >
              {t("Accueil")}
            </span>
          </Link>
          <Link onClick={handleHideSideMenu} href={"/feed"}>
            <span
              className={` duration-300 text-[24px] font-600 ${
                active === "explore" && "text-secondary"
              } `}
            >
              {t("Portail client")}
            </span>
          </Link>
          {(!userIsAuth || (userIsAuth && USER.role !== "freelancer")) && (
            <Link onClick={handleHideSideMenu} href={"/recrutement"}>
              <span
                className={` duration-300 text-[24px] font-600 ${
                  active === "recrutement" && "text-secondary"
                } `}
              >
                {t("Portail main d'oeuvre")}
              </span>
            </Link>
          )}
          <Link onClick={handleHideSideMenu} href={"/home#about"}>
            <span
              className={` duration-300 text-[24px] font-600 ${
                active === "about" && "text-secondary"
              } `}
            >
              {t("A propos")}
            </span>
          </Link> */}
            {/* <Link onClick={handleHideSideMenu} href={"/help"}>
            <span
              className={` duration-300 text-[24px] font-600 ${
                active === "help" && "text-secondary"
              } `}
            >
              {t("Centre d'aide")}
            </span>
          </Link> */}
          </div>
          <div className="w-full">
            {/* {userIsAuth ? (
            <div
              className="flex items-center justify-center gap-2 cursor-pointer group"
              onClick={handleLogout}
            >
              <div className="active-icon">
                <img src="/svg/logout.svg" alt="" />
              </div>
              <span className="text-black font-600 tracking-wider text-[24px] group-hover:text-secondary">
                Déconnecter
              </span>
            </div>
          ) : (
            <div className="w-full flex flex-col items-center gap-[20px]">
              <Link
                onClick={handleHideSideMenu}
                href="/login"
                className="text-[1rem] text-[#923FB3] font-600"
              >
                {t("login")}
              </Link>
              <Link
                onClick={handleHideSideMenu}
                href="/register"
                className="text-[1rem] bg-[#923FB3] text-white p-[10px] rounded-[8px] w-[167px] text-center font-600"
              >
                {t("sign up")}
              </Link>
            </div>
          )} */}
            <div className="py-[30px] flex justify-center">
              <img src="/svg/group-logo.svg" alt="group-logo" />
            </div>
            <div className="flex justify-center">
              <button
                className="flex gap-[5px] px-[20px] items-center justify-center cursor-pointer group relative"
                onClick={() => {
                  router.push(router.asPath, router.asPath, {
                    locale: router.locale === "ar" ? "fr" : "ar",
                  }),
                    handleHideSideMenu();
                }}
              >
                <LanguageIcon
                  className="text-dark duration-300 group-hover:text-secondary"
                  fontSize="small"
                />
                <span className="group-hover:text-secondary duration-300">
                  {router.locale === "ar" ? "Français" : "عربية"}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideMenu;
