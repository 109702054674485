import strings from "../translations/strings";
import { defaultLocale } from "../translations/config";
import { useRouter } from "next/router";

export default function useTranslation() {
  const router = useRouter();
  // console.log(router);
  const { locale } = router;

  const t = (key) => {
    if (!strings[locale][key]) {
      console.warn(`Translation '${key}' for locale '${locale}' not found.`);
    }
    return strings[locale][key] || strings[defaultLocale][key] || "";
  }
  //func 'traducttion' is same like t ;i use it because there is anotther t defined in 'ClientProfileLayout.jsx'
  const traduction = (key) => {
    if (!strings[locale][key]) {
      console.warn(`Translation '${key}' for locale '${locale}' not found.`);
    }
    return strings[locale][key] || strings[defaultLocale][key] || "";
  }
  return {
    t,
    traduction,
    locale,
  };
}
