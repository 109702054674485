const strings = {
  fr: {
    "Une erreur s'est produite lors de la soumission de votre commentaire":
      "Une erreur s'est produite lors de la soumission de votre commentaire",
    "Vérifiez votre boîte e-mail": "Vérifiez votre boîte e-mail",
    "Toutes les informations dont vous avez besoin pour naviguer sur notre site Web et profiter au maximum de nos services.":
      "Toutes les informations dont vous avez besoin pour naviguer sur notre site Web et profiter au maximum de nos services.",

    //facture freelancer
    "Modes de paiement disponibles chez FiDarri":
      "Modes de paiement disponibles chez FiDarri",
    "Compte Postal": "Compte Postal",
    "Compte Boncaire": "Compte Boncaire",
    "Nom du Propriétaire :": "Nom du Propriétaire :",
    "Nom du titulaire :": "Nom du titulaire :",
    "Nom de Bnaque :": "Nom de Bnaque :",
    "Nom de Bnaque :": "Nom de Bnaque :",
    "Numéro de compte :": "Numéro de compte :",
    "Numéro de compte :": "Numéro de compte :",
    "Copier les info": "Copier les info",
    Copié: "Copié",
    "Facturé de": "Facturé de",
    "L’entreprise Fidarri": "L’entreprise Fidarri",
    "Facturé à": "Facturé à",
    "12 Rue Mohamed Belouizdad, Alger,16000 .":
      "12 Rue Mohamed Belouizdad, Alger,16000 .",

    "Désolé, le freelancer que vous recherchez n'existe pas.":
      "Désolé, le freelancer que vous recherchez n'existe pas.",
    "Freelancer non trouvée": "Freelancer non trouvée",
    "Malheureusement aucun travailleur n’est actuellement disponible dans FiDarri, vous pouvez nous rendre visite bientôt, merci pour votre interaction avec nous.":
      "Malheureusement aucun travailleur n’est actuellement disponible dans FiDarri, vous pouvez nous rendre visite bientôt, merci pour votre interaction avec nous.",
    "Chez Feddari, nous accordons une grande importance à nos clients. Votre satisfaction est notre priorité absolue, et nous mettons tout en œuvre pour vous offrir une expérience exceptionnelle .":
      "Chez Fiddari, nous accordons une grande importance à nos clients. Votre satisfaction est notre priorité absolue, et nous mettons tout en œuvre pour vous offrir une expérience exceptionnelle .",
    "Nous comprenons que chaque client est unique, avec des besoins et des attentes spécifiques. C'est pourquoi nous nous engageons à vous fournir un service personnalisé qui répond précisément à vos exigences. Nous écoutons attentivement vos demandes, vos préférences et vos commentaires, et nous nous efforçons de les intégrer dans nos prestations de nettoyage.":
      "Nous comprenons que chaque client est unique, avec des besoins et des attentes spécifiques. C'est pourquoi nous nous engageons à vous fournir un service personnalisé qui répond précisément à vos exigences. Nous écoutons attentivement vos demandes, vos préférences et vos commentaires, et nous nous efforçons de les intégrer dans nos prestations .",
    "Feddari est une plateforme de premier plan dédiée à la fourniture de services exceptionnels de nettoyage pour les maisons et les bureaux. Nous comprenons l'importance d'un environnement propre et hygiénique pour votre bien-être et votre productivité. Avec notre équipe de nettoyeurs hautement qualifiés et professionnels, nous nous efforçons de fournir un service de qualité supérieure qui dépasse vos attentes.":
      "Fiddari est une plateforme de premier plan dédiée à la fourniture de services exceptionnels de nettoyage pour les maisons et les bureaux. Nous comprenons l'importance d'un environnement propre et hygiénique pour votre bien-être et votre productivité. Avec notre équipe de nettoyeurs hautement qualifiés et professionnels, nous nous efforçons de fournir un service de qualité supérieure qui dépasse vos attentes.",
    "N'hésitez pas à venir nous rendre visite pour discuter de vos besoins en matière de nettoyage. Notre équipe sympathique et professionnelle sera ravie de vous accueillir et de vous offrir des solutions adaptées à vos exigences.":
      "N'hésitez pas à venir nous rendre visite pour discuter de vos besoins. Notre équipe sympathique et professionnelle sera ravie de vous accueillir et de vous offrir des solutions adaptées à vos exigences.",
    Accueil: "Accueil",
    Explorer: "Explorer",
    "Portail Client": "Portail Client",
    "Portail main d'oeuvre": "Portail main d'oeuvre",
    accepté: "accepté",

    Du: "Du",
    Au: "Au",
    rejeté: "rejeté",
    "phone mise à jour avec succès": "phone mise à jour avec succès",

    "Recherche par date (sélectionnez un jour ou plusieurs jours) :":
      "Recherche par date (sélectionnez un jour ou plusieurs jours) :",
    "Missions annulées": "Missions annulées",
    "Missions terminées": "Missions terminées",

    Consulter: "Consulter",

    //Freelancer Facture
    "valeur accumulée": "valeur accumulée",
    "Le montant total que vous devez payer.":
      "Le montant total que vous devez payer.",
    Description: "Description",
    Date: "Date",
    "Montant reçu": "Montant reçu",
    Coupon: "Coupon",
    "Montant total": "Montant total",

    //code Promo
    "Chez Fidarri, nous nous engageons à offrir à nos clients les meilleures offres et réductions. Restez à l'écoute pour des promotions incroyables à venir bientôt!":
      "Chez Fidarri, nous nous engageons à offrir à nos clients les meilleures offres et réductions. Restez à l'écoute pour des promotions incroyables à venir bientôt!",

    "Vous pouvez choisir l'un des codes promotionnels à partir ":
      "Vous pouvez choisir l'un des codes promotionnels à partir ",
    "d'ici": "d'ici",

    Profil: "Profile",

    login: "Connexion",
    "sign up": "Inscription",
    "Centre d'aide": "Centre d'aide",
    "A propos": "A propos",
    "Engagez une": "Engagez une",
    travailleuse: "travailleuse",
    "à tout moment": "à tout moment",
    "Que vous soyez un nettoyeur professionnel à la recherche de travail ou quelqu'un ayant besoin de services de nettoyage, nous avons tout ce qu'il vous faut.":
      "Que vous soyez un nettoyeur professionnel à la recherche de travail ou quelqu'un ayant besoin de services de nettoyage, nous avons tout ce qu'il vous faut.",
    Rechercher: "Rechercher",
    "trouver une travailleuse à proximité":
      "trouver une travailleuse à proximité",
    Réserver: "Réserver",
    "Embauchez travailleuse que vous choisissez":
      "Embauchez travailleuse que vous choisissez",
    heureuse: "heureuse",
    "Profitez du service à tout moment": "Profitez du service à tout moment",
    Travailleuses: "Travailleuses",
    "clients satisfaits": "clients satisfaits",
    "nous nous soucions de": "nous nous soucions de",
    "nos clients": "nos clients",
    "vous voulez travailler professionnellement ?":
      "vous voulez travailler professionnellement ?",
    "Développez votre clientèle ! nous saurons vous accompagner.":
      "Développez votre clientèle ! nous saurons vous accompagner.",
    "Rejoignez-nous": "Rejoignez-nous",
    "à propos de nous": "à propos de nous",
    "Voir plus": "Voir plus",
    "réservez facilement": "réservez facilement",
    "trouvez nous sur": "trouvez nous sur",
    "termes et conditions": "termes et conditions",
    "politique de confidentialité": "politique de confidentialité",
    "à propos": "à propos",
    "Code promos": "Code promos",
    contact: "contact",
    "Besoin d'aide ?": "Besoin d'aide ?",
    "Centre d’aide": "Centre d’aide",
    "travailleuses disponibles": "travailleuses disponibles",
    "Recherche une travailleuse": "Recherche une travailleuse",
    "service succès": "services succès",
    services: "services",
    Rating: "Evaluation",
    "Member depuis": "Member depuis",
    Filtre: "Filtre",
    Clear: "Effacer",
    "Garde malade": "Garde malade",
    "Femme de menage": "Femme de menage",
    "Non spécifié": "Non spécifié",
    Type: "Temps de travail",
    "journée complète": "Journée complète",
    "Demi-journée (Matin)": "Demi-journée (Matin)",
    "Demi-journée (Soir)": "Demi-journée (Soir)",

    "Par jour du mois": "Par jour du mois",
    "Garde bébé": "Garde bébé",
    Available: "Disponible",
    "vous devez connecter avec un compte client":
      "vous devez connecter avec un compte client",
    Embaucher: "Embaucher",
    Localisation: "Localisation",
    "FIDDARI est un partenaire dédié aux indépendants, offrant un dispositif complet conçu pour simplifier et enrichir les relations avec les clients par opportunités de développement des compétences et de mise en réseau. En tirant parti de notre approche centrée sur l'utilisateur et de notre technologie innovante, nous permettons aux indépendants d'atteindre facilement leurs objectifs. Rejoignez FIDDARI et libérez tout le potentiel de votre carrière de freelance.":
      "FIDDARI est un partenaire dédié aux indépendants, offrant un dispositif complet conçu pour simplifier et enrichir les relations avec les clients par opportunités de développement des compétences et de mise en réseau. En tirant parti de notre approche centrée sur l'utilisateur et de notre technologie innovante, nous permettons aux indépendants d'atteindre facilement leurs objectifs. Rejoignez FIDDARI et libérez tout le potentiel de votre carrière de freelance.",
    "FIDDARI reconnaît l'importance croissante de l'économie du travail indépendant et comprend les défis uniques auxquels sont confrontés les professionnels indépendants. Nous nous engageons à fournir un système complet qui donne aux freelances les moyens d'atteindre facilement leurs objectifs. En utilisant une technologie innovante, des processus rationalisés et un soutien adapté, nous visons à simplifier l'expérience du travail indépendant, à améliorer la productivité et à favoriser la réussite des freelances dans divers travaux.":
      "FIDDARI reconnaît l'importance croissante de l'économie du travail indépendant et comprend les défis uniques auxquels sont confrontés les professionnels indépendants. Nous nous engageons à fournir un système complet qui donne aux freelances les moyens d'atteindre facilement leurs objectifs. En utilisant une technologie innovante, des processus rationalisés et un soutien adapté, nous visons à simplifier l'expérience du travail indépendant, à améliorer la productivité et à favoriser la réussite des freelances dans divers travaux.",
    "Chez FIDDARI, nous accordons la priorité aux besoins et aux aspirations des freelances. Nous comprenons que les freelances ont besoin de solutions flexibles qui répondent à leurs exigences spécifiques.":
      "Chez FIDDARI, nous accordons la priorité aux besoins et aux aspirations des freelances. Nous comprenons que les freelances ont besoin de solutions flexibles qui répondent à leurs exigences spécifiques.",
    "FIDDARI est un partenaire dédié aux indépendants, offrant un dispositif complet conçu pour simplifier et enrichir les relations avec les clients par opportunités de développement des compétences et de mise en réseau. En tirant parti de notre approche centrée sur l'utilisateur et de notre technologie innovante, nous permettons aux indépendants d'atteindre facilement leurs objectifs. Rejoignez FIDDARI et libérez tout le potentiel de votre carrière de freelance.":
      "FIDDARI est un partenaire dédié aux indépendants, offrant un dispositif complet conçu pour simplifier et enrichir les relations avec les clients par opportunités de développement des compétences et de mise en réseau. En tirant parti de notre approche centrée sur l'utilisateur et de notre technologie innovante, nous permettons aux indépendants d'atteindre facilement leurs objectifs. Rejoignez FIDDARI et libérez tout le potentiel de votre carrière de freelance.",
    "FIDDARI reconnaît l'importance croissante de l'économie du travail indépendant et comprend les défis uniques auxquels sont confrontés les professionnels indépendants. Nous nous engageons à fournir un système complet qui donne aux freelances les moyens d'atteindre facilement leurs objectifs. En utilisant une technologie innovante, des processus rationalisés et un soutien adapté, nous visons à simplifier l'expérience du travail indépendant, à améliorer la productivité et à favoriser la réussite des freelances dans divers travaux.":
      "FIDDARI reconnaît l'importance croissante de l'économie du travail indépendant et comprend les défis uniques auxquels sont confrontés les professionnels indépendants. Nous nous engageons à fournir un système complet qui donne aux freelances les moyens d'atteindre facilement leurs objectifs. En utilisant une technologie innovante, des processus rationalisés et un soutien adapté, nous visons à simplifier l'expérience du travail indépendant, à améliorer la productivité et à favoriser la réussite des freelances dans divers travaux.",
    "Chez FIDDARI, nous accordons la priorité aux besoins et aux aspirations des freelances. Nous comprenons que les freelances ont besoin de solutions flexibles qui répondent à leurs exigences spécifiques.":
      "Chez FIDDARI, nous accordons la priorité aux besoins et aux aspirations des freelances. Nous comprenons que les freelances ont besoin de solutions flexibles qui répondent à leurs exigences spécifiques.",
    /* Portail main d'oeuvre page */
    "Devenez une travailleuse chez nous": "Devenez une travailleuse chez nous",
    "travaillez selon des horaires flexibles et recevez un salaire compétitif, tout en offrant un service précieux à nos clients.":
      "travaillez selon des horaires flexibles et recevez un salaire compétitif, tout en offrant un service précieux à nos clients.",
    "S’inscrire Gratuitement": "S’inscrire Gratuitement",
    "Travaillez où et quand vous voulez": "Travaillez où et quand vous voulez",
    "Gagnez jusqu’à 40,000DZD par mois": "Gagnez jusqu’à 40,000DZD par mois",
    "Améliorer vos revenus": "Améliorer vos revenus",
    "Protocole de sécurité": "Protocole de sécurité",
    "Exécuter des vérifications": "Exécuter des vérifications",
    "Vérification d'identité": "Vérification d'identité",
    "Prévention de la fraude": "Prévention de la fraude",
    "Examens de sécurité": "Examens de sécurité",
    "Comment ça marche ?": "Comment ça marche ?",
    "Remplissez le formulaire avec vos informations correctes, cela prendra quelques minutes.":
      "Remplissez le formulaire avec vos informations correctes, cela prendra quelques minutes.",
    "choisissez une date pour faire un entretien avec notre équipe.":
      "choisissez une date pour faire un entretien avec notre équipe.",
    "Une fois accepté,accédez à votre tableau de bord pour choisir quand et où vous souhaitez travailler.":
      "Une fois accepté,accédez à votre tableau de bord pour choisir quand et où vous souhaitez travailler.",
    "Devenir une travailleuse": "Devenir une travailleuse",
    Login: "Login",
    /* inscription ou connexion page */
    Connexion: "Connexion",
    "adresse mail": "adresse mail",
    "Entrer votre email": "Entrer votre email",
    "Mot de passe": "Mot de passe",
    "Entrer le mot de passe": "Entrer le mot de passe",
    "Enregistrer mes infos de connexion": "Enregistrer mes infos de connexion",
    "Mot de passe oublié ?": "Mot de passe oublié ?",
    "Vous n'avez pas de compte ?": "Vous n'avez pas de compte ?",
    "Créer un compte !": "Créer un compte !",
    "Utilisateur non trouvé": "Utilisateur non trouvé",
    "Mot de passe incorrecte": "Mot de passe incorrecte",
    "Vous devez activer votre compte": "Vous devez activer votre compte",
    /* inscription Layout page */
    "S'inscrire": "S'inscrire",
    "Entrer votre Surnom unique": "Entrer votre Surnom unique",
    Nom: "Nom",
    "Votre Nom": "Votre Nom",
    Prenom: "Prenom",
    "Votre Prenom": "Votre Prenom",
    Telephone: "Telephone",
    "adresse mail": "adresse mail",
    "Entrer votre  email": "Entrer votre  email",
    "Mot de passe": "Mot de passe",
    "Entrer le mot de passe": "Entrer le mot de passe",
    "Vous avez un compte ?": "Vous avez un compte ?",
    "S'inscrire": "S'inscrire",
    "s'identifier": "s'identifier",
    "Nom d'utilisateur invalide. lettres et chiffres uniquement, sans commencer ni se terminer par '.' ou '_', et non __ ou _. ou ._ ou .. à l'intérieur.":
      "Nom d'utilisateur invalide. lettres et chiffres uniquement, sans commencer ni se terminer par '.' ou '_', et non __ ou _. ou ._ ou .. à l'intérieur.",
    "Il existe déjà un utilisateur avec cet adresse email":
      "Il existe déjà un utilisateur avec cet adresse email",
    "Le nom d'utilisateur doit contenir au moins 8 caractères":
      "Le nom d'utilisateur doit contenir au moins 8 caractères",
    "Le nom d'utilisateur doit contenir au maximum 20 caractères":
      "Le nom d'utilisateur doit contenir au maximum 20 caractères",
    "Veuillez sélectionner au moins un type de freelance":
      "Veuillez sélectionner au moins un type de freelance",
    "Votre compte a été créé avec succès ! Veuillez vérifier vos e-mails pour activer votre compte.":
      "Votre compte a été créé avec succès ! Veuillez vérifier vos e-mails pour activer votre compte.",
    /* HelpLayout */
    "Centre d’aide ": "Centre d’aide ",
    "Comment pouvons-nous vous aider ?": "Comment pouvons-nous vous aider ?",
    "Bienvenue à": "Bienvenue à",
    "Comment fonctionne la platform Fiddari  ?":
      "Comment fonctionne la platform Fiddari?",
    "Quels types de services qui sont proposés sur Fiddari actuellement ?":
      "Quels types de services qui sont proposés sur Fiddari actuellement ?",
    "Comment puis-je réserver un freelancer sur Fiddari ?":
      "Comment puis-je réserver un freelancer sur Fiddari ?",
    "Comment payer ?": "Comment payer ?",
    "Que se passe-t-il si je dois modifier mes information?":
      "Que se passe-t-il si je dois modifier mes information?",
    "Vous ne trouvez pas la réponse que vous cherchez ?":
      "Vous ne trouvez pas la réponse que vous cherchez ?",
    "Veuillez contacter notre equipe": "Veuillez contacter notre equipe",
    /* ClientProfileLayout form */
    "Le numéro de téléphone n'est pas valide":
      "Le numéro de téléphone n'est pas valide",
    "Les numéros de téléphone ne correspondent pas":
      "Les numéros de téléphone ne correspondent pas",
    "le mot de passe doit contenir au moins un chiffre":
      "le mot de passe doit contenir au moins un chiffre",
    "Le mot de passe doit contenir au moins une lettre majuscule":
      "Le mot de passe doit contenir au moins une lettre majuscule",
    "À propos de moi": "À propos de moi",
    "Demandes réussies": "services réussies",
    Avis: "Avis",
    "Member depuis": "Member depuis",
    services: "services",
    "Mes avis": "Mes avis",
    "Mon profil": "Mon profil",
    "Mes commandes": "Mes commandes",
    "En attente": "En attente",
    Commencé: "Commencé",
    complété: "complété",
    Paramètres: "Paramètres",
    "Mes adresses": "Mes adresses",
    "Nom de la rue": "Nom de la rue",
    Province: "Province",
    "Code Postal": "Code Postal",
    "Nombre de pieces": "Nombre de pieces",
    Maison: "Maison",
    Supprimer: "Supprimer",
    Freelancer: "Freelancer",
    /* FreelancertProfileLayout */
    "Ancien mot de passe erroné": "Ancien mot de passe erroné",
    "Il n y pas encore de commentaires": "Il n y pas encore de commentaires",
    "Vous devez connecter avec un compte client qui effectue cette transaction.":
      "Vous devez connecter avec un compte client qui effectue cette transaction.",
    "Mes offres": "Mes offres",
    "Malheureusement, aucun travailleur n'a été trouvé disponible grâce à cette recherche, veuillez effectuer une nouvelle recherche":
      "Malheureusement, aucun travailleur n'a été trouvé disponible grâce à cette recherche, veuillez effectuer une nouvelle recherche",
    Acceptés: "Acceptés",
    refusés: "refusés",
    refusé: "refusé",
    missions: "missions",
    "mission annulée": "Mission annulée",
    "la mission a été annulée": "Mission annulée",
    "programme de travail": "programme de travail",
    Paramètres: "Paramètres",
    dépenses: "dépenses",
    Commantaires: "Commantaires",
    "Il n y pas encore de commentaires": "Il n y pas encore de commentaires",
    "Mes offres": "Mes offres",
    Acceptés: "Acceptés",
    refusés: "refusés",
    missions: "missions",
    "programme de travail": "programme de travail",
    Paramètres: "Paramètres",
    dépenses: "dépenses",
    Commantaires: "Commantaires",
    "choisissez une date pour ajouter a la liste de votre temps de Disponibilité !":
      "choisissez une date pour ajouter a la liste de votre temps de Disponibilité !",
    "Sélectionner une date": "Sélectionner une date",
    "sélectionner l'heure de disponibilité":
      "sélectionner l'heure de disponibilité",
    ajouter: "ajouter",
    "Non disponible": "Non disponible",
    "Journée complète": "Journée complète",
    "Demi journée (Matin)": "Demi journée (Matin)",
    "Demi journée (Soir)": "Demi journée (Soir)",
    "Nom et Prénom": "Nom et Prénom",
    "Adresse email": "Adresse email",
    "Numero de telephone": "Numero de telephone",
    "dépenses totales": "dépenses totales",
    dernier: "dernier",
    jours: "jours",
    mission: "mission",
    DZD: "DZD",
    "code promo appliqué": "code promo appliqué",
    fois: "fois",
    "Revenu mensuel moyen": "Revenu mensuel moyen",
    "Gagner ce mois-ci": "Gagner ce mois-ci",
    "factures hebdomadaires": "factures hebdomadaires",
    "Chez FiDarri, nous comprenons l'importance d'une facturation précise et en temps opportun pour maintenir des relations solides avec nos précieux clients. Avec l'introduction de notre système nous visons à rationaliser le processus de facturation, en garantissant efficacité et clarté pour les deux parties concernées.":
      "Chez FiDarri, nous comprenons l'importance d'une facturation précise et en temps opportun pour maintenir des relations solides avec nos précieux clients. Avec l'introduction de notre système nous visons à rationaliser le processus de facturation, en garantissant efficacité et clarté pour les deux parties concernées.",
    "dernière transaction": "dernière transaction",
    Modifier: "Modifier",
    Vide: "Vide",
    Client: "Client",
    Adresse: "Adresse",
    Date: "Date",
    "Temps de travail": "Temps de travail",
    Services: "Services",
    Service: "Service",
    Prix: "Prix",
    Actions: "Actions",
    "Journée complète": "Journée complète",
    "1/2 (matin)": "1/2 (matin)",
    "1/2 (soir)": "1/2 (soir)",
    "Adresse, i.e Bab E-zouar": "Adresse, i.e Bab E-zouar",
    "je suis un gard malade capable de rester toute la nuit sous concentration et trés prudent de mon malade et j'ai l'experience de 2 ans.":
      "je suis un gard malade capable de rester toute la nuit sous concentration et trés prudent de mon malade et j'ai l'experience de 2 ans.",
    supprimer: "supprimer",
    ID: "ID",
    "Date d'émission": "Date d'émission",
    Délais: "Délais",
    Montant: "Montant",
    Coupon: "Coupon",
    Frais: "Frais",
    Status: "Status",
    Actions: "Actions",
    Address: "Address",
    "Montant reçu": "Montant reçu",
    Age: "Age",
    "Service succès": "Service succès",
    "Member depuis": "Member depuis",
    Résumé: "Résumé",
    Commentaires: "Commentaires",
    "Nettoyeur indépendant hautement qualifié et expérimenté, passionné par la fourniture de services de nettoyage de premier ordre à ses clients y compris le nettoyage résidentiel et commercial, le nettoyage de tapis et le nettoyage en profondeur Avec des années d'expérience dans l'industrie .":
      "Nettoyeur indépendant hautement qualifié et expérimenté, passionné par la fourniture de services de nettoyage de premier ordre à ses clients y compris le nettoyage résidentiel et commercial, le nettoyage de tapis et le nettoyage en profondeur Avec des années d'expérience dans l'industrie .",
    "Adresse detail": "Adresse detail",
    "23000DZD": "23000DZD",
    "L'état": "L'état",
    /* InscriptionLayout */
    "S'inscrire": "S'inscrire",
    Nom: "Nom",
    "Votre Nom": "Votre Nom",
    Prenom: "Prenom",
    "Votre Nom": "Votre Nom",
    surnom: "surnom",
    "Entrer un surnom": "Entrer un surnom",
    "Date de naissance": "Date de naissance",
    Telephone: "Telephone",
    "adresse mail": "adresse mail",
    "Entrer votre  email": "Entrer votre  email",
    "Mot de passe": "Mot de passe",
    "Entrer le mot de passe": "Entrer le mot de passe",
    "Pièce d'identité": "Pièce d'identité",
    "Attachez le recto de votre pièce d'identité*":
      "Attachez le recto de votre pièce d'identité*",
    "l'image de la carte d'identité est requise":
      "l'image de la carte d'identité est requise",
    adresse: "adresse",
    "adresse(nom de la rue ,numéro de porte)":
      "adresse(nom de la rue ,numéro de porte)",
    "les mieux notés": "les mieux notés",
    "Voir plus": "Voir plus",
    Province: "Province",
    alger: "alger",
    wilaya: "wilaya",
    "Code postal": "Code postal",
    "Type de travail": "Type de travail",
    "Garde malade": "Garde malade",
    "Femme de ménage": "Femme de ménage",
    "Garde bébé": "Garde bébé",
    "Disponibilité de travail": "Disponibilité de travail",
    "a.i 30 hours": "a.i 30 hours",
    "Conditions Générales d'Utilisation": "Conditions Générales d'Utilisation",
    "Politique de confidentialité": "Politique de confidentialité",
    Réinitialiser: "Réinitialiser",
    "S'inscrire": "S'inscrire",
    "encours...": "encours...",
    /* profile menu in the header */
    "Votre profile": "Votre profile",
    "Centre d'aide": "Centre d'aide",
    Déconnecter: "Déconnecter",
    Déconnecter: "Déconnecter",
    /* side menu */
    "Paramétres du compte": "Paramétres du compte",
    "Horaire de travail": "Horaire de travail",
    Déconnecter: "Déconnecter",
    "Mon copmte": "Mon copmte",
    "Chercher une information": "Chercher une information",
    "Paramétres du compte": "Paramétres du compte",
    "Horaire de travail": "Horaire de travail",
    Déconnecter: "Déconnecter",
    "Mon copmte": "Mon copmte",
    "Chercher une information": "Chercher une information",
    /* toasts */
    "Vous devez sélectionez une date": "Vous devez sélectionez une date",
    "Bio updated succefully": "Bio updated succefully",
    "home added succefully": "home added succefully",
    "home deleted succefully": "home deleted succefully",
    "home updated succefully": "home updated succefully",
    "commentaire envoyé !": "commentaire envoyé !",
    "Vous devez entrer un commentaire !": "Vous devez entrer un commentaire !",
    "home updated succefully": "home updated succefully",
    "Bio updated succefully": "Bio updated succefully",
    "availability deleted succefully": "availability deleted succefully",
    "commentaire envoyé !": "commentaire envoyé !",
    "Vous devez entrer un commentaire !": "Vous devez entrer un commentaire !",
    "Image updated succefully": "Image updated succefully",
    "Image upload failed": "Image upload failed",
    "vous devez connecter avec un compte client":
      "vous devez connecter avec un compte client",
    "Vous devez choisir une maison ,une date et un service !":
      "Vous devez choisir une maison ,une date et un service !",
    "Autonomiser les freelances : libérez votre potentiel avec FIDDARI":
      "Autonomiser les freelances : libérez votre potentiel avec FIDDARI",
    "Découvrez votre intérêt parfait : Connectez-vous avec les abonneés FIDDARI":
      "Découvrez votre intérêt parfait : Connectez-vous avec les abonneés FIDDARI",
    //auth translation form
    "Email est nécessaire à remplir": "Email est nécessaire à remplir",
    "le mot de passe est nécessaire à remplir":
      "le mot de passe est nécessaire à remplir",
    "l'utilisateur invalide": "l'utilisateur invalide",
    "this field is required": "this field is required",
    "Invalid email": "Invalid email",
    "numéro de téléphone invalide": "numéro de téléphone invalide",
    //ClientProfileLayout form
    "ce champ est nécessaire à remplir": "ce champ est nécessaire à remplir",
    "codePostal n'est pas valide": "codePostal n'est pas valide",
    "password ne correspondent pas": "password ne correspondent pas",
    "doit être un nombre": "doit être un nombre",
    "Le prénom est requis": "Le prénom est requis",
    "Nom d'utilisateur est nécessaire": "Nom d'utilisateur est nécessaire",
    "Le nom est obligatoire": "Le nom est obligatoire",
    "Le numéro de téléphone n'est pas valide":
      "Le numéro de téléphone n'est pas valide",
    "Ancien mot de passe": "Ancien mot de passe",
    "Password must be at least 8 characters":
      "Mot de passe doit être d'au moins 8 caractères",
    "Modifier votre email": "Modifier votre email",
    "Nouveau email": "Nouveau email",
    "Invalid email": "Invalid email",
    "Le mot de passe doit être au moins de 8 caractères":
      "Le mot de passe doit être au moins de 8 caractères",
    "Le mot de passe doit contenir au moins un chiffre":
      "Le mot de passe doit contenir au moins un chiffre",
    "Le mot de passe doit contenir au moins une lettre majuscule":
      "Le mot de passe doit contenir au moins une lettre majuscule",
    "le mot de passe est nécessaire à remplir":
      "le mot de passe est nécessaire à remplir",
    Cancel: "Annuler",
    Submit: "Valider",
    Valider: "Valider",
    Annuler: "Annuler",
    "caractères restants": "caractères restants",
    "Créer une biographie": "Créer une biographie",
    "Modifier le numéro de téléphone": "Modifier le numéro de téléphone",
    "Nouveau numéro de téléphone": "Nouveau numéro de téléphone",
    "Modifier le mot de passe": "Modifier le mot de passe",
    "Confirmer le mot de passe": "Confirmer le mot de passe",
    "Nouveau mot de passe": "Nouveau mot de passe",
    "Confirmer le numéro de téléphone": "Confirmer le numéro de téléphone",
    "Si vous ne l'avez pas déjà fait, veuillez vous assurer de compléter vos informations d'adresse sur notre plateforme.":
      "Si vous ne l'avez pas déjà fait, veuillez vous assurer de compléter vos informations d'adresse sur notre plateforme.",
    "Pour ajouter votre adresse, il vous suffit de cliquer sur “Ajouter une nouvelle maison“.":
      "Pour ajouter votre adresse, il vous suffit de cliquer sur “Ajouter une nouvelle maison“.",
    "Ajouter nouvelle Maison": "Ajouter nouvelle Maison",
    "Temps de travail": "Temps de travail",
    "La photo de profile est nécessaire": "La photo de profile est nécessaire",
    "Changer la Photo": "Changer la Photo",
    "Photo de profile": "Photo de profile",
    "Attachez votre profil photo": "Attachez votre profil photo",
    "Sélectionner un fichier": "Sélectionner un fichier",
    "ou faites glisser et dépose ici": "ou faites glisser et dépose ici",
    "JPG ou PNG, taille de fichier ne dépassant pas 10 Mo":
      "JPG ou PNG, taille de fichier ne dépassant pas 10 Mo",
    completé: "completé",
    "en attente de validation": "en attente de validation",
    "en cours": "en cours",
    commencé: "commencé",
    "Numero de téléphone": "Numero de téléphone",
    "en attente": "en attente",
    "codePostal n'est pas valide": "codePostal n'est pas valide",
    valider: "valider",
    soumettre: "soumettre",
    " DZD ": " DZD ",
    "Membre depuis": "Membre depuis",
    demandes: "demandes",
    "Intervalle de temps...": "Intervalle de temps...",
    "Last 24 Hours": "Last 24 Hours",
    "Last 48 Hours": "Last 48 Hours",
    "Last Week": "Last Week",
    "Last Tow Weeks": "Last Tow Weeks",
    "Last Month": "Last Month",

    "non payée": "non payée",
    accumulé: "accumulé",
    payé: "payé",
    "en retard": "en retard",
    "payé avec succès": "payé avec succès",
    "Le numéro de téléphone ne correspondent pas":
      "Le numéro de téléphone ne correspondent pas",
    "Ajouter un commentaire": "Ajouter un commentaire",
    Accepter: "Accepter",
    Refuser: "Refuser",
    Commencer: "Commencer",

    // Forget Password Layout
    "Cet adresse email n'est pas valide": "Cet adresse email n'est pas valide",
    "Ce champ est obligatoire": "Ce champ est obligatoire",
    "Aucun compte n'est enregistré avec cet e-mail":
      "Aucun compte n'est enregistré avec cet e-mail",
    "Réinitialisation du mot de passe": "Réinitialisation du mot de passe",
    "Nous vous avons envoyé un e-mail pour changer votre mot de passe":
      "Nous vous avons envoyé un e-mail pour changer votre mot de passe",
    "Aller à la page d'accueil": "Aller à la page d'accueil",
    //reset password Layout
    "Le mot de passe est court, il doit comporter au moins 8 caractères":
      "Le mot de passe est court, il doit comporter au moins 8 caractères",
    "Les mots de passe doivent être les mêmes":
      "Les mots de passe doivent être les mêmes",
    "Confirmation du mot de passe": "Confirmation du mot de passe",
    "Votre mot de passe a été mis à jour avec succès":
      "Votre mot de passe a été mis à jour avec succès",
    //userActivation Layout
    "Votre compte a été activé avec succès":
      "Votre compte a été activé avec succès",
    "Le lien ne fonctionne pas": "Le lien ne fonctionne pas",
    Réactiver: "Réactiver",
    //userReactivation Layout
    "Il n'y a pas d'utilisateur avec cet e-mail":
      "Il n'y a pas d'utilisateur avec cet e-mail",
    "Un e-mail d'activation a été envoyé à votre adresse e-mail, vous pouvez le renvoyer en cliquant sur le bouton":
      "Un e-mail d'activation a été envoyé à votre adresse e-mail, vous pouvez le renvoyer en cliquant sur le bouton",
    Renvoyer: "Renvoyer",
    envoyer: "envoyer",
    "Un nouvel e-mail a été envoyé à votre adresse e-mail":
      "Un nouvel e-mail a été envoyé à votre adresse e-mail",
    //SEND OFFER
    "Envoyer une offre": "Envoyer une offre",
    "Choisissez une maison": "Choisissez une maison",
    pieces: "pieces",
    "ajouter une nouvelle maison": "ajouter une nouvelle maison",
    "Disponibilité pour le travail": "Disponibilité pour le travail",
    "Service demandé": "Service demandé",
    "Services...": "Services...",
    "Continuer l’étape suivante": "Continuer l’étape suivante",
    "Voulez-vous vraiment supprimer cette maison ?":
      "Voulez-vous vraiment supprimer cette maison ?",
    "Veuillez saisir le code promo": "Veuillez saisir le code promo",
    Appliquer: "Appliquer",
    "Confirmer et Envoyer": "Confirmer et Envoyer",
    CODEPROMO: "CODEPROMO",
    "votre demande a été soumise avec succès":
      "votre demande a été soumise avec succès",
    "Aller au profile": "Aller au profile",
    Verifié: "Verifié",

    //code coupons
    "fidarri coupons": "Fidarri Coupons",
    "code coupon": "code coupon",
    "de réduction avec les coupons fiddari":
      "de réduction avec les coupons fiddari",
    "utilisations, plus vite avant la fin de l’offre!":
      "utilisations, plus vite avant la fin de l’offre!",
    utilisé: "utilisé",
    "Ce code de réduction expire après": "Ce code de réduction expire après",
    " DZD": " DZD",
    "Reçu CCP": "Reçu CCP",
    "Reçu CCP est requis": "Reçu CCP est requis",
    "Reçu CCP envoyé avec succès": "Reçu CCP envoyé avec succès",
    "Reçu CCP envoyé échoué": "Reçu CCP envoyé échoué",
    "date demission": "date demission",
    Delais: "Delais",
    Total: "Total",
    Commission: "Commission",
    "Les cotisations doivent être payées dans un délai maximum d'une semaine pour éviter la fermeture de votre compte":
      "Les cotisations doivent être payées dans un délai maximum d'une semaine pour éviter la fermeture de votre compte",
    "Attachez le recto de votre reçu accusé par la poste algerie":
      "Attachez le recto de votre reçu accusé par la poste algerie",
    Envoyer: "Envoyer",
    facture: "facture",
    erreur: "erreur",
    succès: "succès",
    "rendez-vous de suivi": "rendez-vous de suivi",
    Salut: "Salut",
    "Vérification de l'Email": "Vérification de l'Email",
    "Remarque : vous devez vérifier votre compte par votre adresse e-mail pour pouvoir passer un entretien.":
      "Remarque : vous devez vérifier votre compte par votre adresse e-mail pour pouvoir passer un entretien.",
    "Entretien pour accepter votre demande de travail.":
      "Entretien pour accepter votre demande de travail.",
    "Choisissez la date": "Choisissez la date",
    Confirmer: "Confirmer",
    "Avoir reçu votre candidature pour l'entretien":
      "Avoir reçu votre candidature pour l'entretien",
    "nous vous prions de bien vouloir patienter jusqu'à ce que nous vous envoyions un e-mail avec les détails nécessaires":
      "nous vous prions de bien vouloir patienter jusqu'à ce que nous vous envoyions un e-mail avec les détails nécessaires",
    "Bio mise à jour avec succès": "Bio mise à jour avec succès",
    "maison ajoutée avec succès": "maison ajoutée avec succès",
    "maison supprimée avec succès": "maison supprimée avec succès",
    "maison mise à jour avec succès": "maison mise à jour avec succès",
    "Image mise à jour avec succès": "Image mise à jour avec succès",
    "le téléchargement de l'image a échoué":
      "le téléchargement de l'image a échoué",
    "Texte copié avec succès": "Texte copié avec succès",
    "disponibilité supprimée avec succès":
      "disponibilité supprimée avec succès",
    "Votre compte a été créé avec succès ! Veuillez vérifier vos e-mails pour activer votre compte.":
      "Votre compte a été créé avec succès ! Veuillez vérifier vos e-mails pour activer votre compte.",
    "Code Promo utilisée avec success": "Code Promo utilisée avec success",
    "Mot de passe mis à jour avec succès":
      "Mot de passe mis à jour avec succès",
    "Le téléchargement de l'image a échoué":
      "Le téléchargement de l'image a échoué",

    "Merci, nous sommes ravis de vous accueillir dans notre communauté.":
      "Merci, nous sommes ravis de vous accueillir dans notre communauté.",
    "Veuillez noter que votre inscription est en attente d'approbation de la part de nos administrateurs. Ce processus prend généralement de 1 à 2 jours ouvrables et nous vous remercions de votre patience pendant que nous examinons votre demande":
      "Veuillez noter que votre inscription est en attente d'approbation de la part de nos administrateurs. Ce processus prend généralement de 1 à 2 jours ouvrables et nous vous remercions de votre patience pendant que nous examinons votre demande",

    "404 error": "404 error",
    "Page non trouvée": "Page non trouvée",
    "Désolé, la page que vous recherchez n'existe pas.":
      "Désolé, la page que vous recherchez n'existe pas.",
    "En utilisant ce site, vous déclarez avoir lu et accepté toutes les conditions suivantes :":
      "En utilisant ce site, vous déclarez avoir lu et accepté toutes les conditions suivantes :",
    "Nous avons le droit de modifier, d’ajouter ou de supprimer toute clause du présent Contrat à tout moment sans en informer les abonnés, alors veuillez consulter les modalités avant de faire un départ.":
      "Nous avons le droit de modifier, d’ajouter ou de supprimer toute clause du présent Contrat à tout moment sans en informer les abonnés, alors veuillez consulter les modalités avant de faire un départ.",
    "vous devez vérifier les Conditions Générales d'Utilisation et Politique de confidentialité avant de soumettre":
      "vous devez vérifier les Conditions Générales d'Utilisation et Politique de confidentialité avant de soumettre",
    "Échec du téléchargement de l’image": "Échec du téléchargement de l’image",
    "Disponibilité De Travail doit être au moins de 1 numéro et au plus de 2 numéros":
      "Disponibilité De Travail doit être au moins de 1 numéro et au plus de 2 numéros",
    "Disponibilité De Travail doit être un nombre":
      "Disponibilité De Travail doit être un nombre",
    "Disponibilité de travail (nombre d'heures par semaine)":
      "Disponibilité de travail (nombre d'heures par semaine)",

    len: "len",
    Payé: "Payé",
    "votre commentaire ...": "votre commentaire ...",
    "le numero de telephone déja utilisé":
      "le numero de telephone déja utilisé",
    "le nom d'utilisateur est déja utilisé":
      "le nom d'utilisateur est déja utilisé",
    "l'image modifiée avec succès": "l'image modifiée avec succès",
    "Dernières 24 heures": "Dernières 24 heures",
    "Dernières 48 heures": "Dernières 48 heures",
    "La semaine dernière": "La semaine dernière",
    "Deux dernières semaines": "Deux dernières semaines",
    "Le mois dernier": "Le mois dernier",
    "Veuillez utiliser un code promo valide":
      "Veuillez utiliser un code promo valide",
    "L'utilisateur n'est pas encore vérifié par l'administration":
      "L'utilisateur n'est pas encore vérifié par l'administration",
    "cet utilisateur est déjà bloqué par l'administration":
      "cet utilisateur est déjà bloqué par l'administration",
    "Cette date est non disponible ou déjà occupée":
      "Cette date est non disponible ou déjà occupée",
    "La mission a déjà commencé ou est en cours":
      "La mission a déjà commencé ou est en cours",
    "cet coupon est incorrect": "cet coupon est incorrect",
    //Centre d'aide
    "La plateforme FIDDARI met simplement à disposition des freelancer qualifiés dans leurs domaines pour fournir les services demandés par les abonnés de la plateforme en tant que clients":
      "La plateforme FIDDARI met simplement à disposition des freelancer qualifiés dans leurs domaines pour fournir les services demandés par les abonnés de la plateforme en tant que clients",

    "S’identifier avec un compte client ou créer un nouveau sur portail client  si vous avez pas.":
      "S’identifier avec un compte client ou créer un nouveau sur portail client  si vous avez pas.",

    "Aller au portail main d’oeuvre et sélectionne le freelancer selon les besoins du filtre":
      "Aller au portail main d’oeuvre et sélectionne le freelancer selon les besoins du filtre",

    "Cliquez sur le boutton Embocher": "Cliquez sur le boutton Embocher",

    "Choisis une maison une date et service correspondant":
      "Choisis une maison une date et service correspondant",

    "Cliquez sur aller à l’étape suivante":
      "Cliquez sur aller à l’étape suivante",

    "Appliquez le code coupon pour benificier une réduction sur le prix si c’est disponible":
      "Appliquez le code coupon pour benificier une réduction sur le prix si c’est disponible",

    "En fin tu peut réserver le freelancer en cliquant sur confirmer et envoyer":
      "En fin tu peut réserver le freelancer en cliquant sur confirmer et envoyer",

    "Suiver votre demande avec votre freelancer et felicitation !.":
      "Suiver votre demande avec votre freelancer et felicitation !.",

    "tu peut consulter et modifier votre infomation dans le module paramettre dans votre propre compte":
      "tu peut consulter et modifier votre infomation dans le module paramettre dans votre propre compte",

    "Le payment se faite deux fois ; enter le client et le freelancer ,et entre le freelancer et l'administration Fiddari.":
      "Le payment se faite deux fois ; enter le client et le freelancer ,et entre le freelancer et l'administration Fiddari.",
    "Entre client et freelancer": "Entre client et freelancer",
    "On note que le payment ce faite en cash entre le client el leur freelancer appropriée après que la mission terminé":
      "On note que le payment ce faite en cash entre le client el leur freelancer appropriée après que la mission terminé",
    "Entre le freelancer et l'administration FIDDARI":
      "Entre le freelancer et l'administration FIDDARI",
    "Le payment est visionné en bref dans le module DÉPENSES après la connexion en tant que freelancer":
      "Le payment est visionné en bref dans le module DÉPENSES après la connexion en tant que freelancer",
    "Dans la section ‘Factures hebdomadaire'les factures généré automatiquement par la platforme chaque semaine":
      "Dans la section ‘Factures hebdomadaire'les factures généré automatiquement par la platforme chaque semaine",
    "Le freelancer doit payer à l’administration FIDDARI 20% du totale généré pour chaque facture":
      "Le freelancer doit payer à l’administration FIDDARI 20% du totale généré pour chaque facture",
    "Si le totale à payer généré ne dépasse pas 500da ,la facture accumulé jusqu’à atteindre cette dernier seuil.":
      "Si le totale à payer généré ne dépasse pas 500da ,la facture accumulé jusqu’à atteindre cette dernier seuil.",
    "Si le totale à payer  généré dépasse 500da , le freelancer soumettre la facture pour payer par CCP ou par compte bancaire en envoi un reçu du transaction à l’administration FIDDARI.":
      "Si le totale à payer  généré dépasse 500da , le freelancer soumettre la facture pour payer par CCP ou par compte bancaire en envoi un reçu du transaction à l’administration FIDDARI.",
    "Le freelancer ne doit pas dépasse un délai de 7 jours pour payer, sinon l’administration peut bloque le compte du concerné jusqu’il payer.":
      "Le freelancer ne doit pas dépasse un délai de 7 jours pour payer, sinon l’administration peut bloque le compte du concerné jusqu’il payer.",
    "après que le freelancer paye avec succès il attend la validation par l’administration":
      "après que le freelancer paye avec succès il attend la validation par l’administration",
    "l’administration vérifie le reçu ccp/bancaire pour valider le payement.":
      "l’administration vérifie le reçu ccp/bancaire pour valider le payement.",
    "A propos de nous !": "A propos de nous !",
    "A propos de nous": "A propos de nous",
    "CE QUI NOUS A INTERESSE": "CE QUI NOUS A INTERESSE",

    "Nous nous concentrons sur la réalisation d'une plate-forme hautement personnalisable et évolutive conçue pour répondre aux besoins des client pour trouver leur freelancers idéal.":
      "Nous nous concentrons sur la réalisation d'une plate-forme hautement personnalisable et évolutive conçue pour répondre aux besoins des client pour trouver leur freelancers idéal.",
    "QUI NOUS SOMMES": "QUI NOUS SOMMES",
    "CONTACTEZ NOUS": "CONTACTEZ NOUS",
    Email: "Email",
    "Le nouveau mot de passe doit être différent de l'ancien":
      "Le nouveau mot de passe doit être différent de l'ancien",
    "Mot de pass modifié avec succès": "Mot de pass modifié avec succès",
  },

  ar: {
    "Une erreur s'est produite lors de la soumission de votre commentaire":
      "حدث خطأ أثناء إرسال تعليقك",
    "Vérifiez votre boîte e-mail": "قم بتفقد بريدك الإلكتروني",

    "Toutes les informations dont vous avez besoin pour naviguer sur notre site Web et profiter au maximum de nos services.":
      "جميع المعلومات التي تحتاجها لتصفح موقعنا والاستفادة القصوى من خدماتنا.",
    "les mieux notés": "الأعلى تقييما",
    "Voir plus": "شاهد المزيد",

    //Facture freelancer
    "Modes de paiement disponibles chez FiDarri":
      "طرق الدفع المتاحة في FiDarri",
    "Compte Postal": "حساب بريدي",
    "Compte Boncaire": "حساب بنكي",
    "Nom du Propriétaire :": "أسم المالك :",
    "Nom du titulaire :": "إسم صاحب البطاقة :",
    "Nom de Bnaque :": "اسم البنك :",
    "Numéro de compte :": "رقم الحساب :",
    "Copier les info": "نسخ المعلومات",
    Copié: "نسخ",
    "Facturé de": "الفاتورة من",
    "L’entreprise Fidarri": "شركة FiDarri",
    "Facturé à": "الفاتورة إلى",
    "12 Rue Mohamed Belouizdad, Alger,16000 .":
      "12 شارع محمد بلوزداد ، الجزائر العاصمة ، 16000.",

    "Désolé, le freelancer que vous recherchez n'existe pas.":
      "آسف، العامل الذي تبحث عنه غير موجود.",
    "Freelancer non trouvée": "لا توجد نتائج",
    "Malheureusement aucun travailleur n’est actuellement disponible dans FiDarri, vous pouvez nous rendre visite bientôt, merci pour votre interaction avec nous.":
      "للأسف لا توجد عاملة متاحة حاليا في FiDarri ، يمكنك زيارتنا في وقت قريب ، نشكرك على تعاملك معنا.",
    "Chez Feddari, nous accordons une grande importance à nos clients. Votre satisfaction est notre priorité absolue, et nous mettons tout en œuvre pour vous offrir une expérience exceptionnelle .":
      "في Fiddari  ، نولي أهمية كبيرة لعملائنا. رضاك هو أولويتنا القصوى ، ونحن نفعل كل ما في وسعنا لنوفر لك تجربة استثنائية.",
    "Nous comprenons que chaque client est unique, avec des besoins et des attentes spécifiques. C'est pourquoi nous nous engageons à vous fournir un service personnalisé qui répond précisément à vos exigences. Nous écoutons attentivement vos demandes, vos préférences et vos commentaires, et nous nous efforçons de les intégrer dans nos prestations de nettoyage.":
      "نحن نتفهم أن كل عميل فريد من نوعه ، وله احتياجات وتوقعات محددة. هذا هو السبب في أننا ملتزمون بتزويدك بخدمة شخصية تلبي متطلباتك بدقة. نستمع بعناية لطلباتك وتفضيلاتك وتعليقاتك ، ونسعى جاهدين لدمجها في خدماتنا.",
    "Feddari est une plateforme de premier plan dédiée à la fourniture de services exceptionnels de nettoyage pour les maisons et les bureaux. Nous comprenons l'importance d'un environnement propre et hygiénique pour votre bien-être et votre productivité. Avec notre équipe de nettoyeurs hautement qualifiés et professionnels, nous nous efforçons de fournir un service de qualité supérieure qui dépasse vos attentes.":
      "Feddari هي منصة رائدة مكرسة لتقديم خدمات تنظيف استثنائية للمنازل والمكاتب. نحن نتفهم أهمية البيئة النظيفة والصحية لرفاهيتك وإنتاجيتك. مع فريقنا من عمال النظافة المدربين تدريباً عالياً والمحترفين ، نسعى جاهدين لتقديم خدمة عالية الجودة تفوق توقعاتك.",
    "N'hésitez pas à venir nous rendre visite pour discuter de vos besoins en matière de nettoyage. Notre équipe sympathique et professionnelle sera ravie de vous accueillir et de vous offrir des solutions adaptées à vos exigences.":
      "لا تتردد في القدوم لزيارتنا لمناقشة احتياجاتك. سيسعد فريقنا الودود والمحترف بالترحيب بك وتقديم حلول تتناسب مع متطلباتك.",

    "Malheureusement, aucun travailleur n'a été trouvé disponible grâce à cette recherche, veuillez effectuer une nouvelle recherche":
      "للأسف لم يتم العثور على أي عاملة متاحة عبر عملية البحث هذه ، الرجاء البحث من جديد",
    accepté: "مقبول",

    Du: "من",
    Au: "إلى",
    rejeté: "مرفوضة",
    "phone mise à jour avec succès": "تم تحديث رقم الهاتف بنحاج",

    "Recherche par date (sélectionnez un jour ou plusieurs jours) :":
      "البحث عن طريق التاريخ ( اختيار يوم أو عدة أيام ) :",
    "Missions annulées": "المهام الملغاة",
    "Missions terminées": "المهام المكتملة",

    Consulter: "مراجعة",

    //Freelancer facture
    "valeur accumulée": "القيمة المتراكمة",
    "Le montant total que vous devez payer.":
      "المبلغ الإجمالي الذي يتعين عليك دفعه.",
    Description: "وصف",
    Date: "تاريخ",
    "Montant reçu": "المبلغ الذي تسلمه",
    Coupon: "قسيمة",
    "Montant total": "المبلغ الإجمالي",

    //Code Promo
    "Chez Fidarri, nous nous engageons à offrir à nos clients les meilleures offres et réductions. Restez à l'écoute pour des promotions incroyables à venir bientôt!":
      "في Fidarri ، نحن ملتزمون بتزويد عملائنا بأفضل العروض والخصومات. ترقبوا العروض الترويجية المذهلة قريبا!",

    "Vous pouvez choisir l'un des codes promotionnels à partir ":
      "يمكنك اختيار احد الرموز الترويجية من",
    "d'ici": "هنا",

    Profil: "الحساب",
    Accueil: "الصفحة الرئيسية",
    Explorer: "استكشف",
    Recrutement: "توظيف",
    "Portail Client": "بوابة العملاء",
    "Portail main d'oeuvre": "بوابة العمل",
    login: "تسجيل الدخول",
    "sign up": "تسجيل جديد",
    "Centre d'aide": "مركز المساعدة",
    "A propos": "من نحن",
    "Engagez une": "استأجر",
    travailleuse: "عاملة",
    "à tout moment": "في أي وقت",
    "Que vous soyez un nettoyeur professionnel à la recherche de travail ou quelqu'un ayant besoin de services de nettoyage, nous avons tout ce qu'il vous faut.":
      "سواء كنت منظفًا محترفًا تبحث عن عمل أو شخصًا بحاجة إلى خدمات التنظيف ، فنحن نوفر لك ما تحتاجه.",
    Rechercher: "ابحث",
    "trouver une travailleuse à proximité": "ابحث عن عامل في مكان قريب",
    Réserver: "احجز",
    "Embauchez travailleuse que vous choisissez": "استأجر العامل الذي تختاره",
    heureuse: "سعيد",
    "Profitez du service à tout moment": "استمتع بالخدمة في أي وقت",
    Travailleuses: "عاملة",
    "clients satisfaits": "زبون راضٍ",
    "nous nous soucions de": "نحن نهتم",
    "nos clients": "بعملائنا",
    "vous voulez travailler professionnellement ?": "تريد العمل باحتراف؟",
    "Développez votre clientèle ! nous saurons vous accompagner.":
      "قم بتطوير قاعدة عملائك! سنكون قادرين على مرافقتك.",
    "Rejoignez-nous": "انضم إلينا",
    "à propos de nous": "في ما يخصنا",
    "Voir plus": "شاهد المزيد",
    "réservez facilement": "احجز بسهولة",
    "trouvez nous sur": "تجدنا على",
    "termes et conditions": "الأحكام والشروط",
    "politique de confidentialité": "سياسة الخصوصية",
    "à propos": "في ما يخص",
    "Code promos": "الخصومات",
    contact: "تواصل",
    "Besoin d'aide ?": "بحاجة الى مساعدة ؟",
    "Centre d’aide": "مركز المساعدة",
    "travailleuses disponibles": "العاملات  المتاحة",
    "Recherche une travailleuse": "ابحث عن عاملة",
    "service succès": "خدمات ناجحة",
    services: "خدمات",
    Rating: "التقييم",
    "Member depuis": "عضو منذ",
    Filtre: "فلتر",
    Clear: "امسح",
    "Garde malade": "مرافق مريض",
    "Femme de menage": "  خادمة تنظيف ",
    "Non spécifié": "غير محدد",
    Type: "وقت العمل",
    "journée complète": "يوم كامل",
    "Demi-journée (Matin)": "نصف يوم (صباحا)",
    "Demi-journée (Soir)": "نصف يوم (مساء)",

    "Par jour du mois": "حسب التاريخ",
    "Garde bébé": "مربية أطفال",
    Available: "متوفر",
    "vous devez connecter avec un compte client":
      "يجب عليك تسجيل الدخول بحساب عميل",
    Embaucher: "استأجر",
    Localisation: "حسب الموقع",
    "Devenez une": "Devenez une",
    /* Portail main d'oeuvre page */
    "Devenez une travailleuse chez nous": "كن عاملا معنا",
    "travaillez selon des horaires flexibles et recevez un salaire compétitif, tout en offrant un service précieux à nos clients.":
      "العمل لساعات مرنة والحصول على راتب تنافسي ، مع تقديم خدمة قيمة لعملائنا.",
    "S’inscrire Gratuitement": "سجل مجانا",
    "Travaillez où et quand vous voulez": "اعمل أينما ومتى تريد",
    "Gagnez jusqu’à 40,000DZD par mois": "اربح ما يصل إلى 40،000 دج شهريًا",
    "Améliorer vos revenus": "حسن دخلك",
    "Protocole de sécurité": "بروتوكول الأمان",
    "Exécuter des vérifications": "تشغيل الشيكات",
    "Vérification d'identité": "التحقق من الهوية",
    "Prévention de la fraude": "منع الغش",
    "Examens de sécurité": "مراجعات الأمن",
    "Comment ça marche ?": "كيف تعمل معنا ؟",
    "Remplissez le formulaire avec vos informations correctes, cela prendra quelques minutes.":
      "املأ النموذج بمعلوماتك الصحيحة ، سيستغرق الأمر بضع دقائق.",
    "choisissez une date pour faire un entretien avec notre équipe.":
      "اختر موعدًا لإجراء مقابلة مع فريقنا.",
    "Une fois accepté,accédez à votre tableau de bord pour choisir quand et où vous souhaitez travailler.":
      "بمجرد القبول ، قم بالوصول إلى لوحة التحكم الخاصة بك لاختيار متى وأين تريد العمل.",
    "Devenir une travailleuse": "كن عاملا",
    "FIDDARI reconnaît l'importance croissante de l'économie du travail indépendant et comprend les défis uniques auxquels sont confrontés les professionnels indépendants. Nous nous engageons à fournir un système complet qui donne aux freelances les moyens d'atteindre facilement leurs objectifs. En utilisant une technologie innovante, des processus rationalisés et un soutien adapté, nous visons à simplifier l'expérience du travail indépendant, à améliorer la productivité et à favoriser la réussite des freelances dans divers travaux.":
      "تدرك FIDDARI الأهمية المتزايدة للاقتصاد المستقل وتتفهم التحديات الفريدة التي يواجهها المحترفون المستقلون. نحن ملتزمون بتوفير نظام شامل يمكّن المستقلين من تحقيق أهدافهم بسهولة. باستخدام تقنية مبتكرة وعمليات مبسطة ودعم مخصص ، نهدف لتبسيط تجربة العمل المستقل ، وتحسين الإنتاجية ودفع النجاح للعاملين لحسابهم الخاص في مجموعة متنوعة من الاعمال.",
    "FIDDARI est un partenaire dédié aux indépendants, offrant un dispositif complet conçu pour simplifier et enrichir les relations avec les clients par opportunités de développement des compétences et de mise en réseau. En tirant parti de notre approche centrée sur l'utilisateur et de notre technologie innovante, nous permettons aux indépendants d'atteindre facilement leurs objectifs. Rejoignez FIDDARI et libérez tout le potentiel de votre carrière de freelance.":
      "FIDDARI هو شريك مخصص للموظفين المستقلين ، ويقدم نظامًا شاملاً مصممًا لتبسيط وتعزيز العلاقات مع العملاء ، وتوفير فرص لتطوير المهارات والتواصل. من خلال الاستفادة من نهجنا الذي يركز على المستخدم والتكنولوجيا المبتكرة ، فإننا نمكّن المستقلين من تحقيق أهدافهم بسهولة. انضم إلى FIDDARI وافتح الإمكانات الكاملة لحياتك المهنية المستقلة.",
    "Chez FIDDARI, nous accordons la priorité aux besoins et aux aspirations des freelances. Nous comprenons que les freelances ont besoin de solutions flexibles qui répondent à leurs exigences spécifiques.":
      "في FIDDARI ، نعطي الأولوية لاحتياجات وتطلعات المستقلين. نحن ندرك أن العاملين لحسابهم الخاص يحتاجون إلى حلول مرنة تلبي متطلباتهم الخاصة.",
    "Devenez une travailleuse chez nous": "كن عاملا معنا",
    "travaillez selon des horaires flexibles et recevez un salaire compétitif, tout en offrant un service précieux à nos clients.":
      "العمل لساعات مرنة والحصول على راتب تنافسي ، مع تقديم خدمة قيمة لعملائنا.",
    "S’inscrire Gratuitement": "سجل مجانا",
    "Travaillez où et quand vous voulez": "اعمل أينما ومتى تريد",
    "Gagnez jusqu’à 40,000DZD par mois": "اربح ما يصل إلى 40،000 دج شهريًا",
    "Améliorer vos revenus": "حسن دخلك",
    "Protocole de sécurité": "بروتوكول الأمان",
    "Exécuter des vérifications": "تشغيل الشيكات",
    "Vérification d'identité": "التحقق من الهوية",
    "Prévention de la fraude": "منع الغش",
    "Examens de sécurité": "مراجعات الأمن",
    "Comment ça marche ?": "كيف تعمل معنا ؟",
    "Remplissez le formulaire avec vos informations correctes, cela prendra quelques minutes.":
      "املأ النموذج بمعلوماتك الصحيحة ، سيستغرق الأمر بضع دقائق.",
    "choisissez une date pour faire un entretien avec notre équipe.":
      "اختر موعدًا لإجراء مقابلة مع فريقنا.",
    "Une fois accepté,accédez à votre tableau de bord pour choisir quand et où vous souhaitez travailler.":
      "بمجرد القبول ، قم بالوصول إلى لوحة التحكم الخاصة بك لاختيار متى وأين تريد العمل.",
    "Devenir une travailleuse": "كن عاملا",
    "FIDDARI reconnaît l'importance croissante de l'économie du travail indépendant et comprend les défis uniques auxquels sont confrontés les professionnels indépendants. Nous nous engageons à fournir un système complet qui donne aux freelances les moyens d'atteindre facilement leurs objectifs. En utilisant une technologie innovante, des processus rationalisés et un soutien adapté, nous visons à simplifier l'expérience du travail indépendant, à améliorer la productivité et à favoriser la réussite des freelances dans divers travaux.":
      "تدرك FIDDARI الأهمية المتزايدة للاقتصاد المستقل وتتفهم التحديات الفريدة التي يواجهها المحترفون المستقلون. نحن ملتزمون بتوفير نظام شامل يمكّن المستقلين من تحقيق أهدافهم بسهولة. باستخدام تقنية مبتكرة وعمليات مبسطة ودعم مخصص ، نهدف لتبسيط تجربة العمل المستقل ، وتحسين الإنتاجية ودفع النجاح للعاملين لحسابهم الخاص في مجموعة متنوعة من الاعمال.",
    "FIDDARI est un partenaire dédié aux indépendants, offrant un dispositif complet conçu pour simplifier et enrichir les relations avec les clients par opportunités de développement des compétences et de mise en réseau. En tirant parti de notre approche centrée sur l'utilisateur et de notre technologie innovante, nous permettons aux indépendants d'atteindre facilement leurs objectifs. Rejoignez FIDDARI et libérez tout le potentiel de votre carrière de freelance.":
      "FIDDARI هو شريك مخصص للموظفين المستقلين ، ويقدم نظامًا شاملاً مصممًا لتبسيط وتعزيز العلاقات مع العملاء ، وتوفير فرص لتطوير المهارات والتواصل. من خلال الاستفادة من نهجنا الذي يركز على المستخدم والتكنولوجيا المبتكرة ، فإننا نمكّن المستقلين من تحقيق أهدافهم بسهولة. انضم إلى FIDDARI وافتح الإمكانات الكاملة لحياتك المهنية المستقلة.",
    "Chez FIDDARI, nous accordons la priorité aux besoins et aux aspirations des freelances. Nous comprenons que les freelances ont besoin de solutions flexibles qui répondent à leurs exigences spécifiques.":
      "في FIDDARI ، نعطي الأولوية لاحتياجات وتطلعات المستقلين. نحن ندرك أن العاملين لحسابهم الخاص يحتاجون إلى حلول مرنة تلبي متطلباتهم الخاصة.",
    /* inscription ou connexion page */
    Connexion: "تسجيل الدخول",
    "adresse mail": "عنوان البريد الإلكتروني",
    "Entrer votre email": "أدخل بريدك الإلكتروني",
    "Mot de passe": "كلمة السر",
    "Entrer le mot de passe": "أدخل كلمة المرور",
    "Enregistrer mes infos de connexion": "حفظ تفاصيل تسجيل الدخول",
    "Mot de passe oublié ?": "نسيت كلمة السر ؟",
    "Vous n'avez pas de compte ?": "لا يوجد لديك حساب ؟",
    "Créer un compte !": "سجل من جديد",
    Login: "دخول",
    "Utilisateur non trouvé": "هذا المستخدم غير موجود",
    "Mot de passe incorrecte": "كلمة سر خاطئة",
    "Vous devez activer votre compte": "يجب عليك تفعيل حسابك",
    /* inscription Layout page */
    " DZD": " دج",
    "S'inscrire": "سجل الان",
    "Entrer votre Surnom unique": "أدخل لقبك الفريد",
    Nom: "الاسم",
    "Votre Nom": "اسمك",
    Prenom: "اللقب",
    "Votre Prenom": "لقبك",
    Telephone: "هاتفك",
    "adresse mail": "عنوان البريد الإلكتروني",
    "Entrer votre  email": "أدخل بريدك الإلكتروني",
    "Mot de passe": "كلمة السر",
    "Entrer le mot de passe": "أدخل كلمة المرور",
    "Vous avez un compte ?": " يوجد لديك حساب ؟",
    "S'inscrire": "تسجيل",
    "s'identifier": "دخول",
    "Il existe déjà un utilisateur avec cet adresse email":
      "يوجد بالفعل مستخدم مسجل بهذا البريد الإلكتروني ",
    "Nom d'utilisateur invalide. lettres et chiffres uniquement, sans commencer ni se terminer par '.' ou '_', et non __ ou _. ou ._ ou .. à l'intérieur.":
      "اسم مستخدم غير صحيح. أحرف وأرقام فقط ، لا تبدأ أو تنتهي بـ '.' أو '_' ولا يحتوي على __ أو _. أو ._ أو .. بالداخل.",
    "Le nom d'utilisateur doit contenir au moins 8 caractères":
      "يجب أن يكون إسم المستخدم يحتوي على الأقل 8 أحرف",
    "Le nom d'utilisateur doit contenir au maximum 20 caractères":
      "يجب ألا يزيد اسم المستخدم عن 20 حرفًا",
    "Votre compte a été créé avec succès ! Veuillez vérifier vos e-mails pour activer votre compte.":
      "تم إنشاء حسابك بنجاح! يرجى التحقق من بريدك الإلكتروني لتفعيل حسابك.",
    /* HelpLayout */
    "Centre d’aide": "مركز المساعدة",
    "Comment pouvons-nous vous aider ?": "كيف يمكن لنا أن نساعدكم ؟",
    "Bienvenue à": "مرحبا بك في",
    "Comment fonctionne la platform Fiddari  ?": "كيف تعمل منصة Fiddari؟",
    "Quels types de services qui sont proposés sur Fiddari actuellement ?":
      "ما هي أنواع الخدمات المتوفرة حاليًا على Fiddari؟",
    "Comment puis-je réserver un freelancer sur Fiddari ?":
      "كيف يمكنني حجز عامل مستقل على Fiddari؟",
    "Comment payer ?": "كيفيه الدفع",
    "Que se passe-t-il si je dois modifier mes information?":
      "ماذا لو احتجت إلى تغيير معلوماتي؟",
    "Vous ne trouvez pas la réponse que vous cherchez ?":
      "لا يمكنك العثور على الإجابة التي تبحث عنها؟",
    "Veuillez contacter notre equipe": "يرجى الاتصال بفريقنا",
    /* ClientProfileLayout */
    "Le numéro de téléphone n'est pas valide": "رقم الهاتف غير صالح",
    "Les numéros de téléphone ne correspondent pas":
      "أرقام الهواتف غير متطابقة",
    "Password must be at least 8 characters":
      "يجب أن تتكون كلمة المرور من 8 أحرف على الأقل",
    "le mot de passe doit contenir au moins un chiffre":
      "يجب ان تحتوي كلمة المرور على الاقل رقما واحدا",
    "Le mot de passe doit contenir au moins une lettre majuscule":
      "يجب أن تحتوي كلمة المرور على حرف واحد كبير على الأقل",
    "Modifier votre email": "قم بتغيير بريدك الإلكتروني",
    "Nouveau email": "البريد الالكتروني الجديد",
    "À propos de moi": "نبذه عني",
    "Demandes réussies": "الخدمات الناجحة",
    Avis: "التقييم",
    "Member depuis": "عضو منذ",
    services: "خدمات",
    "Mes avis": "مراجعاتي",
    "Mon profil": "ملفي",
    "Mes commandes": "طلباتي",
    "En attente": "في الانتظار",
    "mission annulée": "المهام الملغاة",
    "la mission a été annulée": "المهمة ملغاة",
    Commencé: "جاري الخدمه",
    complété: "مكتمل",
    Paramètres: "الإعدادات",
    "Mes adresses": "عناويني",
    "Nom de la rue": "اسم الشارع",
    Province: "مقاطعة",
    "Code Postal": "الرقم البريدي",
    "Nombre de pieces": "عدد الغرف",
    Maison: "بيت",
    Supprimer: "حذف",
    Freelancer: "العامل الحر",
    /* FreelancertProfileLayout */
    "Ancien mot de passe erroné": "كلمة المرور القديمة خاطئة",
    "Il n y pas encore de commentaires": "لا توجد تعليقات حتى الآن",
    "Vous devez connecter avec un compte client qui effectue cette transaction.":
      "يجب عليك تسجيل الدخول باستخدام حساب العملاء الذي يقوم بهذه المعاملة.",
    "Mes offres": "عروضي",
    Acceptés: "المقبوله",
    refusés: "المرفوضه",
    refusé: "مرفوضه",
    missions: "المهمات",
    "programme de travail": "برنامج العمل",
    Paramètres: "الإعدادات",
    dépenses: "نفقات",
    Commantaires: "التعليقات",
    "choisissez une date pour ajouter a la liste de votre temps de Disponibilité !":
      "اختر تاريخًا لإضافته إلى قائمة وقت التوفر الخاص بك!",
    "Sélectionner une date": "حدد تاريخ",
    "sélectionner l'heure de disponibilité": "حدد وقت التوفر",
    ajouter: "اضافه",
    "Non disponible": "غير متوفر",
    "Journée complète": "يوم كامل",
    "Demi journée (Matin)": "نصف يوم (صباحا)",
    "Demi journée (Soir)": "نصف يوم (مساء)",
    "Nom et Prénom": "الإسم واللقب",
    "Adresse email": "البريد الإلكتروني",
    "Numero de telephone": "رقم الهاتف",
    Modifier: "تغيير",
    "dépenses totales": "المصروفات الكلية",
    dernier: "الأخير",
    jours: "يوما",
    mission: "مهمه",
    DZD: "دج",
    "code promo appliqué": "الرمز الترويجي المطبق",
    fois: "مرة",
    "Revenu mensuel moyen": "متوسط ​​الدخل الشهري",
    "Gagner ce mois-ci": "ربح هذا الشهر",
    "factures hebdomadaires": "الفواتير الأسبوعية",
    "Chez FiDarri, nous comprenons l'importance d'une facturation précise et en temps opportun pour maintenir des relations solides avec nos précieux clients. Avec l'introduction de notre système nous visons à rationaliser le processus de facturation, en garantissant efficacité et clarté pour les deux parties concernées.":
      "في FiDarri ، نتفهم أهمية إصدار فواتير دقيقة وفي الوقت المناسب للحفاظ على علاقات قوية مع عملائنا الكرام. من خلال تقديم نظامنا ، نهدف إلى تبسيط عملية إصدار الفواتير ، وضمان الكفاءة والوضوح لكلا الطرفين المعنيين.",
    "dernière transaction": "الصفقة الأخيرة",
    Vide: "لا يوجد",
    Client: "العميل",
    Adresse: "عنوان",
    Date: "تاريخ",
    "Temps de travail": "وقت العمل",
    Services: "خدمات",
    Prix: "سعر",
    Actions: "القبول",
    "Journée complète": "يوم كامل",
    "1/2 (matin)": "1/2 (صباحًا)",
    "1/2 (soir)": "1/2 (مساءً)",
    "Adresse, i.e Bab E-zouar": "العنوان ، باب الزوار",
    "je suis un gard malade capable de rester toute la nuit sous concentration et trés prudent de mon malade et j'ai l'experience de 2 ans.":
      "أنا ممرض قادر على البقاء طوال الليل تحت التركيز وحذرًا جدًا من مريضي ولدي خبرة لمدة عامين .",
    supprimer: "حذف",
    ID: "رقم",
    "Date d'émission": "تاريخ الإصدار",
    Délais: "الوقت المحدد",
    Montant: "مبلغ",
    Coupon: "قسيمة",
    Frais: "رسوم",
    Status: "الوضع",
    Address: "عنوان",
    "Montant reçu": "المبلغ المستلم",
    Age: "العمر",
    "Service succès": "خدمه ناجحه",
    Service: "خدمة",
    "Member depuis": "عضو منذ",
    Résumé: "ملخص",
    Commentaires: "التعليقات",
    "Nettoyeur indépendant hautement qualifié et expérimenté, passionné par la fourniture de services de nettoyage de premier ordre à ses clients y compris le nettoyage résidentiel et commercial, le nettoyage de tapis et le nettoyage en profondeur Avec des années d'expérience dans l'industrie .":
      "منظف ​​مستقل مؤهل تأهيلاً عالياً وذو خبرة شغوف بتقديم خدمات تنظيف من الدرجة الأولى لعملائه بما في ذلك التنظيف السكني والتجاري وتنظيف السجاد والتنظيف العميق مع سنوات من الخبرة في الصناعة.",
    "23000DZD": "23000 دج",
    "Membre depuis": "عضو منذ",
    /* InscriptionLayout */
    "S'inscrire": "arab",
    Nom: "الاسم",
    "Votre Nom": "اسمك",
    Prenom: "اللقب",
    "Votre Prenom": "لقبك",
    surnom: "اسم المستخدم",
    "Entrer un surnom": "أدخل اسم المستخدم",
    "Date de naissance": "تاريخ الميلاد",
    Telephone: "الهاتف",
    "adresse mail": "عنوان البريد الإلكتروني",
    "Entrer votre  email": "أدخل بريدك الإلكتروني",
    "Mot de passe": "كلمة المرور",
    "Entrer le mot de passe": "أدخل كلمة المرور",
    "Pièce d'identité": "بطاقة التعريف",
    "Attachez le recto de votre pièce d'identité*":
      "*أدخل  بطاقة التعريف الخاصة بك",
    "l'image de la carte d'identité est requise": "صورة بطاقة الهوية مطلوبة",
    adresse: "العنوان",
    "adresse(nom de la rue ,numéro de porte)":
      "العنوان (اسم الشارع ، رقم الباب)",
    Province: "مقاطعة",
    alger: "الجزائر",
    wilaya: "الولايه",
    "Code postal": "الرقم البريدي",
    "Type de travail": "نوع العمل",
    "Femme de ménage": "عامله التنظيف",
    "Garde bébé": "حارسه الطفل",
    "Disponibilité de travail": "الوقت المتاح للعمل",
    "a.i 30 hours": "مثلا 30 ساعة",
    "Conditions Générales d'Utilisation": "شروط الخدمة",
    "Politique de confidentialité": "سياسة الخصوصية",
    Réinitialiser: "إعادة ضبط",
    "S'inscrire": "تسجيل",
    "encours...": "...جاري التحميل",
    /* profile menu in the header */
    "Votre profile": "ملفك الشخصي",
    "Centre d'aide": "مركز المساعدة",
    Déconnecter: "خروج",
    /* side menu */
    "Paramétres du compte": "إعدادت الحساب",
    "Mon copmte": "حسابي",
    "Chercher une information": "ابحث عن المعلومات",
    /* toasts */
    "Vous devez sélectionez une date": "يجب عليك تحديد تاريخ",
    "Bio updated succefully": "تم تحديث السيرة الذاتية بنجاح",
    "home added succefully": "تمت الاضافه بنجاح",
    "home deleted succefully": "تم الحذف بنجاح",
    "home updated succefully": "تم التعديل بنجاح",
    "commentaire envoyé !": "تم الارسال بنجاح",
    "Vous devez entrer un commentaire !": "يجب إدخال تعليق!",
    "home updated succefully": "تم التعديل بنجاح",
    "Bio updated succefully": "تم التعديل بنجاح",
    "availability deleted succefully": "تم حذف التوفر بنجاح",
    "commentaire envoyé !": "تم الارسال بنجاح",
    "Vous devez entrer un commentaire !": "يجب إدخال تعليق!",
    "Image updated succefully": "تم تحديث الصورة بنجاح",
    "Image upload failed": "فشل تحميل الصورة",
    "vous devez connecter avec un compte client":
      "يجب عليك تسجيل الدخول باستخدام حساب العميل",
    "Vous devez choisir une maison ,une date et un service !":
      "يجب أن تختار منزلًا وتاريخًا وخدمة!",
    "Autonomiser les freelances : libérez votre potentiel avec FIDDARI":
      "أطلق العنان لإمكانياتك مع FIDDARI",
    "Découvrez votre intérêt parfait : Connectez-vous avec les abonneés FIDDARI":
      "اكتشف اهتمامك المثالي: تواصل مع مشتركي FIDDARI",
    //auth translation form
    "Email est nécessaire à remplir": "البريد الإلكتروني مطلوب ليتم ملؤه",
    "le mot de passe est nécessaire à remplir": "مطلوب إدخال كلمة المرور",
    "l'utilisateur invalide": "المستخدم غير موجود",
    "Invalid email": "بريد إلكتروني خاطئ",
    //ClientProfileLayout form
    "ce champ est nécessaire à remplir": "هذا الفراغ ضروري ملؤه",
    "codePostal n'est pas valide": "الرمز البريدي غير صالح",
    "password ne correspondent pas": "كلمة المرور غير متطابقة",
    "doit être un nombre": "يجب أن يكون رقما",
    "Le prénom est requis": "الإسم الأول مطلوب",
    "Nom d'utilisateur est nécessaire": "اسم المستخدم مطلوب",
    "Le nom est obligatoire": "الاسم مطلوب",
    "Le numéro de téléphone n'est pas valide": "رقم الهاتف غير موجود",
    "numéro de téléphone invalide": "رقم الهاتف غير موجود",
    "Invalid email": "بريد إلكتروني خاطئ",
    "Le mot de passe doit être au moins de 8 caractères":
      "يجب أن تتكون كلمة المرور من 8 أحرف على الأقل",
    "Le mot de passe doit contenir au moins un chiffre":
      "يجب ان تحتوي كلمة المرور على الاقل رقما واحدا",
    "Le mot de passe doit contenir au moins une lettre majuscule":
      "يجب أن تحتوي كلمة المرور على حرف واحد كبير على الأقل",
    "le mot de passe est nécessaire à remplir": "مطلوب إدخال كلمة المرور",
    Cancel: "إلغاء",
    Submit: "إرسال",
    "caractères restants": "حرف متبقي",
    "Créer une biographie": "أنشئ سيرة ذاتية",
    "Modifier le numéro de téléphone": "غير رقم الهاتف",
    "Nouveau numéro de téléphone": "رقم هاتف جديد",

    "Ancien mot de passe": "كلمة المرور القديمة",
    "Modifier le mot de passe": "قم بتغيير كلمة المرور",
    "Confirmer le mot de passe": "أكد كلمة المرور",
    "Nouveau mot de passe": "كلمة مرور جديدة",
    "Confirmer le numéro de téléphone": "قم بتأكيد رقم الهاتف",
    "Si vous ne l'avez pas déjà fait, veuillez vous assurer de compléter vos informations d'adresse sur notre plateforme.":
      "إذا لم تكن قد فعلت ذلك بالفعل، فيرجى التأكد من إكمال معلومات العنوان الخاصة بك على منصتنا.",
    "Pour ajouter votre adresse, il vous suffit de cliquer sur “Ajouter une nouvelle maison“.":
      "لإضافة عنوانك ، ما عليك سوى النقر فوق إضافة منزل جديد .",
    "Ajouter nouvelle Maison": "أضف منزل جديد",
    "Temps de travail": "وقت العمل",
    "La photo de profile est nécessaire": "صورة الملف الشخصي مطلوبة",
    "Changer la Photo": "تغيير الصورة",
    "Photo de profile": "صورة الملف الشخصي",
    "Attachez votre profil photo": "ادخل صوره ملفك الشخصي",
    "Sélectionner un fichier": "حدد ملف",
    "ou faites glisser et dépose ici": "او اسحبه الى هنا",
    "JPG ou PNG, taille de fichier ne dépassant pas 10 Mo":
      "JPG أو PNG ، حجم الملف لا يزيد عن 10 ميجابايت",
    completé: "مكتمل",
    "en attente de validation": "في انتظار التأكيد",
    "en cours": "جاري العمل",
    commencé: "بدأ",
    "Numero de téléphone": "رقم الهاتف",
    "en attente": "في انتظار",
    "codePostal n'est pas valide": "الرمز البريدي غير صالح",
    valider: "مصادقة",
    soumettre: "تاكيد",
    DZD: "دج",
    " DZD ": " دج ",
    "L'état": "الحاله",
    demandes: "طلب",
    Valider: "تاكيد",
    Annuler: "الغاء",
    "Intervalle de temps...": "حسب الزمن",
    "Last 24 Hours": "أخر 24 ساعه",
    "Last 48 Hours": "آخر 48 ساعة",
    "Last Week": "الأسبوع الماضي",
    "Last Tow Weeks": "الاسبوعين الماضيين",
    "Last Month": "الشهر الماضي",
    "non payée": "غير مدفوع",
    accumulé: "متراكمة",
    payé: "مدفوع الأجر",
    "en retard": "متأخر",
    "payé avec succès": "دفع الثمن بنجاح",
    "Le numéro de téléphone ne correspondent pas": "رقم الهاتف غير متطابق",
    "Ajouter un commentaire": "إضافة تعليق",
    Accepter: "قبول",
    Refuser: "الغاء",
    Commencer: "بدء",
    // Forget Password Layout
    "Cet adresse email n'est pas valide": "عنوان البريد الإلكتروني غير صالح",
    "Ce champ est obligatoire": "هذه الخانة مطلوبة",
    "Aucun compte n'est enregistré avec cet e-mail":
      "لا يوجد حساب مسجل بهذا البريد الإلكتروني",
    "Réinitialisation du mot de passe": "إعادة تعيين كلمة المرور",
    "Nous vous avons envoyé un e-mail pour changer votre mot de passe":
      "لقد أرسلنا لك بريدًا إلكترونيًا لتغيير كلمة المرور الخاصة بك",
    "Aller à la page d'accueil": "اذهب الى الصفحة الرئيسية",
    //reset password Layout
    "Le mot de passe est court, il doit comporter au moins 8 caractères":
      "كلمة المرور قصيرة ، يجب أن تتكون من 8 أحرف على الأقل",
    "Les mots de passe doivent être les mêmes":
      "يجب أن تكون كلمات المرور متطابقة",
    "Confirmation du mot de passe": "تأكيد كلمة المرور",
    "Votre mot de passe a été mis à jour avec succès":
      "لقد تم تحديث كلمة السر الخاصة بك بنجاح",
    //userActivation Layout
    "Votre compte a été activé avec succès": "تم تفعيل حسابك بنجاح",
    "Le lien ne fonctionne pas": "الرابط لا يعمل",
    Réactiver: "اعادة تفعيل",
    //userReactivation Layout
    "Il n'y a pas d'utilisateur avec cet e-mail":
      "لا يوجد مستخدم بهذا البريد الإلكتروني",
    "Un e-mail d'activation a été envoyé à votre adresse e-mail, vous pouvez le renvoyer en cliquant sur le bouton":
      "تم إرسال بريد إلكتروني للتفعيل إلى عنوان بريدك الإلكتروني. يمكنك إعادة إرسالها بالنقر فوق الزر",
    Renvoyer: "إعادة إرسال",
    envoyer: "إرسال",
    "Un nouvel e-mail a été envoyé à votre adresse e-mail":
      "تم إرسال بريد إلكتروني جديد إلى عنوان بريدك الإلكتروني",
    //SEND OFFER
    "Envoyer une offre": "ارسال العرض",
    "Choisissez une maison": "اختر منزلًا",
    pieces: "الغرف",
    "ajouter une nouvelle maison": "إضافة منزل جديد",
    "Disponibilité pour le travail": "التوفر للعمل",
    "Service demandé": "الخدمة المطلوبة",
    "Services...": "خدمات...",
    "Continuer l’étape suivante": "تابع إلى الخطوة التالية",
    "Voulez-vous vraiment supprimer cette maison ?":
      "هل تريد حقا حذف هذا المنزل ؟",
    "Veuillez saisir le code promo": "الرجاء إدخال الرمز الترويجي",
    Appliquer: "تفعيل",
    "Confirmer et Envoyer": "تأكيد وإرسال",
    CODEPROMO: "الرمز الترويجي",
    "votre demande a été soumise avec succès": "تم تقديم طلبك بنجاح",
    "Aller au profile": "انتقل إلى الملف الشخصي",
    Verifié: "مصادق",

    //code coupons
    "fidarri coupons": "كوبونات فيداري",
    "code coupon": "رمز الكوبون",
    "de réduction avec les coupons fiddari": "الخصم مع كوبونات فيداري",
    "utilisations, plus vite avant la fin de l’offre!":
      "مستخدم ، أسرع قبل انتهاء العرض!",
    utilisé: "مستخدم",
    "Ce code de réduction expire après": "تنتهي صلاحية رمز الخصم هذا بعد",

    "Reçu CCP": "وصل الحساب البريدي",
    "Reçu CCP est requis": "مطلوب CCP وصل",
    "Reçu CCP envoyé avec succès": "تم الارسال بنجاح",
    "Reçu CCP envoyé échoué": "لقد تعذر الارسال",
    "date demission": "تاريخ البرنامج",
    Delais: "المهلة",
    Total: "مجموع",
    Commission: "عمولة",
    "Les cotisations doivent être payées dans un délai maximum d'une semaine pour éviter la fermeture de votre compte":
      "يجب دفع الاشتراكات في غضون أسبوع واحد كحد أقصى لتجنب إغلاق حسابك",
    "Attachez le recto de votre reçu accusé par la poste algerie":
      "إرفاق مقدمة إيصال البريد الجزائري",
    Envoyer: "ارسال",
    facture: "فاتورة",
    erreur: "خطأ",
    succès: "نجاح",
    "rendez-vous de suivi": "مواعيد المتابعة",
    Salut: "مرحبا",
    "Vérification de l'Email": "التحقق من البريد الإلكتروني",
    "Remarque : vous devez vérifier votre compte par votre adresse e-mail pour pouvoir passer un entretien.":
      "ملاحظة: يجب عليك التحقق من حسابك باستخدام عنوان بريدك الإلكتروني لإجراء مقابلة.",
    "Entretien pour accepter votre demande de travail.":
      "مقابلة لقبول طلب عملك.",
    "Choisissez la date": "اختر تاريخ",
    Confirmer: "تاكيد",
    "Avoir reçu votre candidature pour l'entretien": "لقد تلقيت طلبك للمقابلة",
    "nous vous prions de bien vouloir patienter jusqu'à ce que nous vous envoyions un e-mail avec les détails nécessaires":
      "يرجى الانتظار حتى نرسل لك بريدًا إلكترونيًا مع التفاصيل اللازمة",
    "Bio mise à jour avec succès": "تم تحديث السيرة الذاتية بنجاح",
    "maison ajoutée avec succès": "تمت إضافة المنزل بنجاح",
    "maison supprimée avec succès": "تم حذف المنزل بنجاح",
    "maison mise à jour avec succès": "تم تحديث المنزل بنجاح",
    "Image mise à jour avec succès": "تم تحديث الصورة بنجاح",
    "le téléchargement de l'image a échoué": "فشل تحميل الصورة",
    "Texte copié avec succès": "نسخ النص بنجاح",
    "disponibilité supprimée avec succès": "تمت إزالة التوفر بنجاح",
    "Votre compte a été créé avec succès ! Veuillez vérifier vos e-mails pour activer votre compte.":
      "تم إنشاء حسابك بنجاح! يرجى التحقق من بريدك الإلكتروني لتفعيل حسابك.",
    "Code Promo utilisée avec success": "تم استخدام الرمز الترويجي بنجاح",
    "Mot de passe mis à jour avec succès": "تم تحديث كلمة السر بنجاح",
    "Merci, nous sommes ravis de vous accueillir dans notre communauté.":
      "شكرًا لك ، يسعدنا أن نرحب بك عندنا .",
    "Veuillez noter que votre inscription est en attente d'approbation de la part de nos administrateurs. Ce processus prend généralement de 1 à 2 jours ouvrables et nous vous remercions de votre patience pendant que nous examinons votre demande":
      "يرجى ملاحظة أن تسجيلك في انتظار الموافقة من المسؤولين لدينا. تستغرق هذه العملية عادةً من يوم إلى يومي عمل ونحن نقدر سعة صدرك أثناء مراجعة طلبك.",
    "404 error": "404 خطأ",
    "Page non trouvée": "الصفحة غير موجودة",
    "Désolé, la page que vous recherchez n'existe pas.":
      "معذرة ، الصفحة التي تبحث عنها غير موجودة.    :",
    "En utilisant ce site, vous déclarez avoir lu et accepté toutes les conditions suivantes :":
      "باستخدام هذا الموقع، تعلن أنك قرأت وقبلت جميع الشروط التالية:",
    "Nous avons le droit de modifier, d’ajouter ou de supprimer toute clause du présent Contrat à tout moment sans en informer les abonnés, alors veuillez consulter les modalités avant de faire un départ.":
      "يحق لنا تعديل أو إضافة أو حذف أي بند من هذه الاتفاقية في أي وقت دون إخطار المشتركين، لذلك يرجى الرجوع إلى الشروط والأحكام قبل بدء الاستعمال.",
    "vous devez vérifier les Conditions Générales d'Utilisation et Politique de confidentialité avant de soumettre":
      "يجب عليك التحقق من شروط الخدمة وسياسة الخصوصية قبل الإرسال",
    "Échec du téléchargement de l’image": "فشل تحميل الصورة",
    "Disponibilité De Travail doit être au moins de 1 numéro et au plus de 2 numéros":
      "يجب أن يكون توفر العمل رقمًا واحدًا على الأقل ورقمين على الأكثر",
    "Disponibilité De Travail doit être un nombre":
      "يجب أن يكون 'هذا الحقل' رقمًا",
    "Veuillez sélectionner au moins un type de freelance":
      "الرجاء تحديد نوع عمل واحد على الأقل",
    "Disponibilité de travail (nombre d'heures par semaine)":
      "الوقت المتاح للعمل (عدد الساعات في الأسبوع)",
    Payé: "مدفوع",
    "votre commentaire ...": "تعليقك...",
    "le numero de telephone déja utilisé": "رقم الهاتف مستخدم من قبل",
    "le nom d'utilisateur est déja utilisé": "اسم المستخدم مستخدم من قبل",
    "l'image modifiée avec succès": "تم تغيير الصوره بنجاح",
    "Dernières 24 heures": "أخر 24 ساعه",
    "Dernières 48 heures": "آخر 48 ساعة",
    "La semaine dernière": "الأسبوع الماضي",
    "Deux dernières semaines": "الاسبوعين الماضيين",
    "Le mois dernier": "الشهر الماضي",
    "Veuillez utiliser un code promo valide": "الرجاء استخدام رمز ترويجي موجود",
    "L'utilisateur n'est pas encore vérifié par l'administration":
      "لم يتم التحقق من المستخدم بعد من قبل الإدارة",
    "cet utilisateur est déjà bloqué par l'administration":
      "تم حظر هذا المستخدم  من قبل الإدارة",
    "Cette date est non disponible ou déjà occupée":
      "هذا التاريخ غير متوفر مشغول من قبل",
    "La mission a déjà commencé ou est en cours":
      "المهمة بدأت بالفعل أو في طورالخدمه",
    "cet coupon est incorrect": "رمز الكوبون غير صحيح",
    //Centre d'aide
    "La plateforme FIDDARI met simplement à disposition des freelancer qualifiés dans leurs domaines pour fournir les services demandés par les abonnés de la plateforme en tant que clients":
      "تعمل منصة FIDDARI بكل بساطة على توفير عملاء مؤهلين في مجالاتهم لتوفير الخدمات المطلوبة لدى المشتركين في المنصة كزبائن",

    "S’identifier avec un compte client ou créer un nouveau sur portail client  si vous avez pas.":
      " سجل الدخول بحساب عميل أو أنشئ حسابًا جديدًا على بوابة العملاء.",

    "Aller au portail main d’oeuvre et sélectionne le freelancer selon les besoins du filtre":
      "انتقل إلى بوابة العمل وحدد العامل الحر وفقًا لاحتياجات الفلتر",

    "Cliquez sur le boutton Embocher": "انقر فوق الزر استاجر",

    "Choisis une maison une date et service correspondant":
      "اختر المنزل والتاريخ والخدمة ",

    "Cliquez sur aller à l’étape suivante": "انقر فوق انتقل إلى الخطوة التالية",

    "En fin tu peut réserver le freelancer en cliquant sur confirmer et envoyer":
      "في النهاية يمكنك حجز العامل المستقل بالضغط على تأكيد وإرسال",

    "Suiver votre demande avec votre freelancer et felicitation !.":
      "تابع طلبك مع موظفك المستقل وتتهانيناهنئتك !.",
    "Appliquez le code coupon pour benificier une réduction sur le prix si c’est disponible":
      "استخدم رمز الكوبون للاستفادة من تخفيض السعر إذا كان متاحًا",
    "tu peut consulter et modifier votre infomation dans le module paramettre dans votre propre compte":
      "يمكنك عرض معلوماتك وتعديلها في وحدة إعدادات الحساب  الخاص بك ",

    "Le payment se faite deux fois ; enter le client et le freelancer ,et entre le freelancer et l'administration Fiddari.":
      "يتم الدفع مرتين ؛ بين العميل و العامل الحر ، وبين العامل الحر وإدارة فيداري.",
    "Entre client et freelancer": "بين العميل و العامل الحر",
    "On note que le payment ce faite en cash entre le client el leur freelancer appropriée après que la mission terminé":
      " يجدر بالذكر ان الدفع يتم نقدًا بين العميل و العامل الحر المناسب له بعد انتهاء المهمة",
    "Entre le freelancer et l'administration FIDDARI":
      "بين العامل الحر وإدارة FIDDARI",
    "Le payment est visionné en bref dans le module DÉPENSES après la connexion en tant que freelancer":
      "يتم عرض الدفع باختصار في وحدة المصاريف بعد تسجيل الدخول كعامل الحر",
    "Dans la section ‘Factures hebdomadaire'les factures généré automatiquement par la platforme chaque semaine":
      "في قسم 'الفواتير الأسبوعية' ، يتم إنشاء الفواتير تلقائيًا كل أسبوع",
    "Le freelancer doit payer à l’administration FIDDARI 20% du totale généré pour chaque facture":
      "يجب أن يدفع العامل الحر 20٪ لإدارة FIDARI من الإجمالي الناتج عن كل فاتورة",
    "Si le totale à payer généré ne dépasse pas 500da ,la facture accumulé jusqu’à atteindre cette dernier seuil.":
      "إذا لم يتجاوز إجمالي المبلغ المراد دفعه 500 دج ، فإن الفاتورة تتراكم حتى الوصول إلى الحد المذكور.",
    "Si le totale à payer  généré dépasse 500da , le freelancer soumettre la facture pour payer par CCP ou par compte bancaire en envoi un reçu du transaction à l’administration FIDDARI.":
      "إذا تجاوز إجمالي المبلغ الذي سيتم دفعه 500 دج، فإن العامل الحر يرسل وصل الدفع عن طريق CCP أو عن طريق حساب مصرفي  إلى إدارة FIDDARI.",
    "Le freelancer ne doit pas dépasse un délai de 7 jours pour payer, sinon l’administration peut bloque le compte du concerné jusqu’il payer.":
      "يجب ألا يتجاوز العامل الحر مدة 7 أيام، وإلا فإنه يمكن للإدارة حظر حساب المعني حتى يقوم بالدفع.",
    "après que le freelancer paye avec succès il attend la validation par l’administration":
      "بعد أن يدفع العامل الحر بنجاح ، فإنه ينتظر المصادقة من قبل الإدارة",
    "l’administration vérifie le reçu ccp/bancaire pour valider le payement.":
      "تتحقق الإدارة من إيصال  للتحقق من صحة وصل الدفع.",
    "A propos de nous !": "في ما يخصنا !",
    "A propos de nous": "في ما يخصنا",
    "CE QUI NOUS A INTERESSE": "ما يهمنا ",
    "Nous nous concentrons sur la réalisation d'une plate-forme hautement personnalisable et évolutive conçue pour répondre aux besoins des client pour trouver leur freelancers idéal.":
      "نحن نركز على تقديم نظام قابل للتخصيص بدرجة عالية وقابل للتطوير مصمم لتلبية احتياجات العملاء للعثور على العاملين المثاليين.",
    "QUI NOUS SOMMES": "من نحن",
    "CONTACTEZ NOUS": "اتصل بنا",
    Email: "البريد الالكتروني",
    "Le nouveau mot de passe doit être différent de l'ancien":
      "يجب أن تكون كلمة المرور الجديدة مختلفة عن القديمة",
    "Mot de pass modifié avec succès": "تم تغيير كلمه المرور بنجاح",
  },
};
export default strings;
