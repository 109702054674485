import { useEffect, useState, useRef } from "react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";

//routes
import { routes } from "../lib/routes";
import { userSettings } from "../lib/userSettings";

//mui icons
import LanguageIcon from "@mui/icons-material/Language";

//redux
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../store/slices/userSlice";

//components
import Loading from "../components/Loading";
import SideMenu from "./SideMenu";
import HeaderSkeleton from "./skeletonComp/header/HeaderSkeleton";

//hooks
import useOnClickOutside from "../hooks/useOnClickOutside";
import { getUser } from "../graphql/services";
import useTranslation from "../hooks/useTranslation";
import { Fade } from "react-awesome-reveal";

const Header = ({ active }) => {
  const { t } = useTranslation();
  const menuRef = useRef();
  const menuRef2 = useRef();
  const router = useRouter();
  const dispatch = useDispatch();

  const [isVisible, setIsVisible] = useState(false);
  const [langIsOpen, setLangIsOpen] = useState(false);
  const [sideMenuIsOpen, setsideMenuIsOpen] = useState(false);
  const USER = useSelector((state) => state.auth.user);

  useOnClickOutside(menuRef, () => {
    if (isVisible) {
      setIsVisible(false);
    }
  });
  useOnClickOutside(menuRef2, () => {
    if (langIsOpen) {
      setLangIsOpen(false);
    }
  });

  const userIsAuth = useSelector((state) => state.auth?.isAuth);
  const auth = useSelector((state) => state.auth?.user);

  const handleLogout = () => {
    router.push("/");
    setIsVisible(false);
    setTimeout(() => {
      dispatch(logout());
    }, 1000);
  };

  return (
    <>
      <div
        className={`fixed right-0 left-0 lg:py-[30px] py-[15px] bg-white border-b border-[#C0C0C0] z-30`}
      >
        <div className="max-w-[1366px] mx-auto ">
          <div className="relative px-[30px] flex justify-between items-center lg:items-center items-end text-base capitalize text-dark font-semibold font-myriad-semibold leading-5">
            <Link href="/" className="lg:block hidden">
              <Image src="/svg/logo.svg" width={85} height={47} alt="logo" />
            </Link>{" "}
            <Link href="/" className="lg:hidden block">
              <Image src="/svg/logo.svg" alt="logo" width={80} height={40} />
            </Link>
            {userIsAuth ? (
              <div className="lg:hidden flex gap-[20px] items-center">
                <button className="p-[10px] relative">
                  <Fade>
                    <img src="/svg/notification.svg" alt="" />
                  </Fade>
                  <div className="absolute top-[8px] right-[8px] w-[8px] h-[8px] rounded-full bg-[#D76262]"></div>
                </button>
                <button
                  onClick={() => setsideMenuIsOpen(true)}
                  className="w-[32px] h-[32px] rounded-full bg-[#E7D5EE] flex items-center justify-center "
                >
                  <Fade>
                    <img
                      src={
                        USER?.profilePictureUrl
                          ? USER?.profilePictureUrl
                          : "/svg/profile.svg"
                      }
                      alt="profile"
                      className="object-fill rounded-full w-[32px] h-[32px]"
                    />
                  </Fade>
                </button>
              </div>
            ) : (
              <div className="lg:hidden block">
                <div className="flex items-center gap-[15px]">
                  {/* <button className="pl-[10px] py-[10px]">
                    <Fade>
                      <img src="/svg/search.svg" alt="search" />
                    </Fade>
                  </button> */}

                  <button
                    onClick={() => setsideMenuIsOpen(true)}
                    className="pl-[10px] py-[10px]"
                  >
                    <Fade>
                      <img
                        src="/svg/nmenu.svg"
                        className={`${
                          router.locale === "ar" ? "scale-x-[-1]" : "mr-4"
                        }`}
                        alt="menu"
                      />
                    </Fade>
                  </button>
                </div>
              </div>
            )}
            <div className="lg:flex hidden items-center gap-[70px]">
              <Link href={"/"}>
                <span
                  className={` duration-300 ${
                    active === "home" && "text-secondary"
                  }  ha:hover:text-secondary`}
                >
                  {t("Accueil")}
                </span>
              </Link>
              <Link href={"/feed"}>
                <span
                  className={` duration-300 ${
                    active === "explore" && "text-secondary"
                  }  ha:hover:text-secondary`}
                >
                  {t("Portail Client")}
                </span>
              </Link>

              {(!userIsAuth || (userIsAuth && USER.role !== "Freelancer")) && (
                <Link href={"/recrutement"}>
                  <span
                    className={`duration-300 ${
                      active === "recrutement" && "text-secondary"
                    }  ha:hover:text-secondary`}
                  >
                    {t("Portail main d'oeuvre")}
                  </span>
                </Link>
              )}
              {/* ))} */}
              <div
                ref={menuRef2}
                className="flex items-center gap-1 cursor-pointer group relative"
                onClick={() => setLangIsOpen((prevState) => !prevState)}
              >
                <LanguageIcon
                  className="text-dark duration-300 group-hover:text-secondary"
                  fontSize="small"
                />
                <span className="group-hover:text-secondary duration-300">
                  {router.locale === "ar" ? "عربية" : "Français"}
                </span>

                {langIsOpen && (
                  <div
                    style={{
                      boxShadow: "1px 2px 26px rgba(0, 0, 0, 0.12)",
                    }}
                    className="bg-white rounded-[30px] absolute z-40 -bottom-2 translate-y-full  left-0"
                  >
                    <div className="py-[30px] px-[37px]">
                      <div className="flex flex-col gap-6">
                        <div
                          onClick={() => {
                            router.push(router.asPath, router.asPath, {
                              locale: "ar",
                            });
                          }}
                          className="cursor-pointer "
                        >
                          <div className="flex items-center gap-2 group">
                            <span className="text-dark font-light text-base tracking-wider hover:text-secondary">
                              العربية
                            </span>
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            router.push(router.asPath, router.asPath, {
                              locale: "fr",
                            });
                          }}
                          className="cursor-pointer "
                        >
                          <div className="flex items-center gap-2 group">
                            <span className="text-dark font-light text-base tracking-wider hover:text-secondary">
                              Français
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* {console.log(isAuth)} */}
            {/* {loading ? (
              <HeaderSkeleton />
            ) : ( */}
            <>
              {userIsAuth ? (
                <div
                  ref={menuRef}
                  className="lg:flex hidden items-center gap-[42px] min-w-[240px]"
                >
                  <div className="flex items-center gap-2">
                    <div
                      onClick={() => {
                        if (USER.role === "Client") {
                          router.push("/clientProfile?r=profile");
                        } else if (USER.role === "Freelancer") {
                          router.push("/freelancerProfile?r=profile");
                        }
                      }}
                      className="w-11 h-11 bg-[#E7D5EEB8] rounded-full justify-center flex items-center py-3 cursor-pointer"
                    >
                      <div className=" w-12 min-w-[3rem] h-12 rounded-full p-1">
                        <img
                          src={
                            USER?.profilePictureUrl
                              ? USER?.profilePictureUrl
                              : "/svg/profile.svg"
                          }
                          alt="profile"
                          className="object-fill w-full h-full rounded-full"
                        />
                      </div>
                    </div>
                    <Link
                      href={
                        USER.role === "Client"
                          ? "/clientProfile"
                          : "/freelancerProfile"
                      }
                      className="text-dark font-semibold text-base tracking-[0.075em]"
                    >
                      {USER?.firstName} {USER?.lastName}
                    </Link>
                  </div>
                  <img
                    onClick={() => setIsVisible((prevState) => !prevState)}
                    src="/svg/settings.svg"
                    alt="settings"
                    className="cursor-pointer"
                  />
                  {isVisible && (
                    <div
                      style={{
                        boxShadow: "1px 2px 26px rgba(0, 0, 0, 0.12)",
                      }}
                      className={`bg-white rounded-[30px] absolute z-40 -bottom-2 translate-y-full  ${
                        router.locale === "ar" ? "left-5" : "right-5"
                      }
                  `}
                    >
                      <div className="py-[30px] px-[37px]">
                        <div className="flex flex-col gap-6">
                          {/* {userSettings.map((setting, index) => (
                        <Link key={index} href={setting.href}>
                          <div className="flex items-center gap-2 group">
                            <div className="active-icon">{setting.icon}</div>
                            <span className="text-dark font-light text-base tracking-wider group-hover:text-secondary">
                              {setting.name}
                            </span>
                          </div>
                        </Link>
                      ))} */}
                          <div
                            className="cursor-pointer "
                            onClick={() => {
                              if (USER.role === "Client") {
                                router.push("/clientProfile");
                              } else if (USER.role === "Freelancer") {
                                router.push("/freelancerProfile?r=profile");
                              }
                            }}
                          >
                            <div className="flex items-center gap-2 group">
                              <div className="active-icon">
                                {" "}
                                <svg
                                  className="group-hover:text-secondary"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <circle cx="12" cy="7" r="4" fill="#5A5A5A" />
                                  <path
                                    d="M5.33788 18.3206C5.99897 15.5269 8.77173 14 11.6426 14H12.3574C15.2283 14 18.001 15.5269 18.6621 18.3206C18.79 18.8611 18.8917 19.4268 18.9489 20.0016C19.0036 20.5512 18.5523 21 18 21H6C5.44772 21 4.99642 20.5512 5.0511 20.0016C5.1083 19.4268 5.20997 18.8611 5.33788 18.3206Z"
                                    fill="#5A5A5A"
                                  />
                                </svg>
                              </div>
                              <span className="text-dark font-light text-base tracking-wider group-hover:text-secondary">
                                {t("Votre profile")}
                              </span>
                            </div>
                          </div>
                          <div
                            className="cursor-pointer "
                            onClick={() => {
                              router.push("/help");
                            }}
                          >
                            <div className="flex items-center gap-2 group">
                              <div className="active-icon">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <circle
                                    cx="12"
                                    cy="12"
                                    r="9"
                                    fill="#5A5A5A"
                                  />
                                  <circle
                                    cx="12"
                                    cy="18"
                                    r="0.5"
                                    fill="#33363F"
                                    stroke="white"
                                  />
                                  <path
                                    d="M12 16V14.5811C12 13.6369 12.6042 12.7986 13.5 12.5V12.5C14.3958 12.2014 15 11.3631 15 10.4189V9.90569C15 8.30092 13.6991 7 12.0943 7H12C10.3431 7 9 8.34315 9 10V10"
                                    stroke="white"
                                    stroke-width="2"
                                  />
                                </svg>
                              </div>
                              <span className="text-dark font-light text-base tracking-wider group-hover:text-secondary">
                                {t("Centre d'aide")}
                              </span>
                            </div>
                          </div>
                          <div className="w-full h-[0.75px] bg-[#CBCBCB]"></div>
                          <div
                            className="flex items-center gap-2 cursor-pointer group"
                            onClick={handleLogout}
                          >
                            <div className="active-icon">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.3675 6.54415L16.6838 4.43874C17.3313 4.2229 18 4.70487 18 5.38743V18.6126C18 19.2951 17.3313 19.7771 16.6838 19.5613L10.3675 17.4558C9.55086 17.1836 9 16.4193 9 15.5585V8.44152C9 7.58066 9.55086 6.81638 10.3675 6.54415Z"
                                  fill="#5A5A5A"
                                />
                                <path
                                  d="M6.5 9.5L4 12M4 12L6.5 14.5M4 12H14"
                                  stroke="#262626"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                />
                              </svg>
                            </div>

                            <span className="text-dark font-light text-base tracking-wider group-hover:text-secondary">
                              {t("Déconnecter")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="lg:flex hidden items-center gap-[55px]">
                  <Link href="/login">
                    <span className="text-secondary ha:hover:text-primary duration-300">
                      {t("login")}
                    </span>
                  </Link>
                  <Link
                    href={
                      active === "recrutement" ? "/inscription" : "/register"
                    }
                  >
                    <span className="text-white bg-secondary duration-300 ha:hover:bg-primary py-[12px] px-[23px] rounded-lg">
                      {t("sign up")}
                    </span>
                  </Link>
                </div>
              )}
            </>
            {/* )} */}
          </div>
        </div>
      </div>

      {/* side menu */}
      <SideMenu
        user={USER}
        sideMenuIsOpen={sideMenuIsOpen}
        setsideMenuIsOpen={setsideMenuIsOpen}
        userIsAuth={userIsAuth}
        USER={USER}
        active={active}
        t={t}
        router={router}
        handleLogout={handleLogout}
      />
    </>
  );
};

export default Header;
